import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const ResumeUploader = forwardRef(
  ({ defaultValue, name, getFlag, getSelectedFile }, ref) => {
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const maxSize = 5 * 1024 * 1024; // 5 MB limit

    useImperativeHandle(ref, () => ({
      clearSelectedFile: () => {
        setSelectedFile(null);
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      },
    }));

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file && file.size <= maxSize) {
        setUploading(true);
        // Simulate upload time
        setSelectedFile(file);
        getSelectedFile?.(file);
        getFlag?.(false);
        setUploading(false);
      } else if (file) {
        alert("File size exceeds the 5 MB limit.");
      }
    };

    const removeFile = (e) => {
      e.stopPropagation();
      setSelectedFile(null);
      getSelectedFile?.(null);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    };

    const handleDragOver = (e) => e.preventDefault();

    const handleDrop = (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (file && file.size <= maxSize) {
        setUploading(true);
        setTimeout(() => {
          setSelectedFile(file);
          getSelectedFile?.(file);
          getFlag?.(false);
          setUploading(false);
        }, 500);
      } else if (file) {
        alert("File size exceeds the 5 MB limit.");
      }
    };

    return (
      <Box sx={{ mt: 1, width: "100%", position: "relative" }}>
        <Box
          onClick={() => inputRef.current?.click()}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          sx={{
            border: "1px dashed #b0bec5",
            borderRadius: "8px",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            py: 2,
            px: 3,
            display: "flex",
            alignItems: "center",
            minHeight: "80px",
          }}
        >
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept=".pdf,.doc,.docx"
            name={name}
          />

          {!selectedFile && !uploading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: 42,
                  height: 42,
                  borderRadius: "50%",
                  backgroundColor: "#e3f2fd",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexShrink: 0,
                  cursor: "pointer",
                  transition: "background-color 0.3s ease", // Transition effect
                  "&:hover": {
                    backgroundColor: "#bbdefb", // Slightly darker shade
                  },
                }}
              >
                <UploadIcon sx={{ color: "#0288d1", fontSize: 20 }} />
              </Box>

              <Typography
                variant="body1"
                color="textSecondary"
                component="span"
                sx={{ fontWeight: 600, fontSize: "17px" }}
              >
                Drag & drop or click to upload
              </Typography>
            </Box>
          ) : uploading ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <CircularProgress size={24} />
              <Typography variant="body2">Uploading...</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <UploadFileIcon sx={{ color: "#2e7d32" }} />
                <Typography variant="body2">{selectedFile.name}</Typography>
              </Box>
              <IconButton
                size="small"
                onClick={removeFile}
                aria-label="Remove file"
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

export default ResumeUploader;
