import React, { useState } from "react";
import AnimatedArrow from "../Arrow/AnimatedArrow.js";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NavLinkHeader = ({
  toSubHeaderOne,
  title,
  subHeaderOne,
  subHeaderTwo,
  subHeaderThree,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  const handleClick = () => {
    if (!subHeaderOne && toSubHeaderOne) {
      navigate(toSubHeaderOne);
    }
  };

  return (
    <Box
      style={{ display: "flex", alignItems: "center" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          fontSize: "18px",
          color: isOpen ? "gray" : "#333",
          transition: "color 0.5s ease",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        {title}
      </div>
      {subHeaderOne && (
        <AnimatedArrow
          isOpen={isOpen}
          subHeaderOne={subHeaderOne}
          subHeaderTwo={subHeaderTwo}
          subHeaderThree={subHeaderThree}
          toSubHeaderOne={toSubHeaderOne}
        />
      )}
    </Box>
  );
};

export default NavLinkHeader;
