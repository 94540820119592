import { Typography } from "@mui/material";
import React from "react";
import CareersHeader from "./CareersHeader";
import CareerDetailBody from "./CareerDetailBody";
import { redirect, useLoaderData } from "react-router-dom";
import PageTitle from "../PageTitle";
import { useTheme, useMediaQuery } from "@mui/material";
const CareerDetail = () => {
  const roles = useLoaderData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <PageTitle title={"Position"} />
      <Typography
        component="div"
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "100px",
          justifyContent: "center",
          marginBottom: "30px",
          padding: {
            xs: "0px 30px",
            md: "0px 70px",
            xl: "0px 200px",
          },
        }}
      >
        {!isMobile && <CareersHeader roles={roles} />}
        <CareerDetailBody roles={roles} />
      </Typography>
    </>
  );
};

export default CareerDetail;

export const CarrersLoader = async ({ request, params }) => {
  const { id } = params;
  const roleResponse = await fetch(
    `https://api-gdcleb.aspire.software/Recruitment/gdc-web-pages/in-progress/${id}`
  );

  if (!roleResponse.ok) {
    return redirect("/error");
  }
  const data = await roleResponse.json();

  return data;
};
