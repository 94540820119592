import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  Divider,
} from "@mui/material";
import { Facebook, LinkedIn } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import img from "../../Image/Aspire-Logo-Gray.png";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const TwitterXIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26" // Set the width to 24px
      height="26" // Set the height to 24px
      fill="none"
      viewBox="0 0 512 512"
      id="twitter"
    >
      <g clipPath="url(#clip0_84_15697)">
        <rect width="512" height="512" fill="#fff" rx="60" />{" "}
        {/* White background */}
        <path
          fill="#000" // Set the "X" to black
          d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_84_15697">
          <rect width="512" height="512" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );

  const links = [
    { text: "Home", href: "/" },
    { text: "About us", href: "/WhoWeAre" },
    { text: "Services", href: "/Services" },
    { text: "Careers", href: "/Careers-culture" },
    { text: "Contact us", href: "/ContactUs" },
    // { text: "Social Media", href: "/social" },
  ];

  const clickHandler = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        backgroundColor: "#191919",
        color: "white",
        pt: 12,
        pb: 3,
        opacity: 0.9,
        position: "relative", // This allows for absolute positioning of children
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          justifyContent={{ xs: "center", sm: "flex-start" }}
        >
          {/* Phase 1 */}
          <Grid item xs={12} md={6} textAlign={{ xs: "center", sm: "left" }}>
            <Box
              component="img"
              src={img}
              alt="Aspire Software"
              sx={{ height: 70, width: 200, mb: 2, cursor: "pointer" }}
              onClick={clickHandler}
            />

            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
                justifyContent: { xs: "center", sm: "flex-start" }, // Change made here
              }}
            >
              <LocationOnIcon sx={{ mr: 0.5 }} />
              <Box component="span">
                Dolphin Fattal Building 6th floor, Sin El Fil, Beirut
              </Box>
            </Typography>

            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                justifyContent: { xs: "center", sm: "flex-start" }, // Change made here
              }}
            >
              <PhoneEnabledIcon sx={{ mr: 1 }} />
              <Box
                component="a"
                href="tel:+9613162916"
                sx={{
                  "&:hover": {
                    color: "#bfbbbb",
                    cursor: "pointer",
                    transition: "color 0.3s",
                  },
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                +9613162916
              </Box>
            </Typography>
            <Box>
              <IconButton
                color="inherit"
                aria-label="Facebook"
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/SoftwareAspire/",
                    "_blank"
                  );
                }}
              >
                <Facebook fontSize="large" />
              </IconButton>

              <IconButton
                color="inherit"
                aria-label="LinkedIn"
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/aspire-software/posts/?feedView=all",
                    "_blank"
                  );
                }}
              >
                <LinkedIn fontSize="large" />
              </IconButton>

              <IconButton
                color="inherit"
                aria-label="X"
                onClick={() => {
                  window.open("https://x.com/SoftwareAspire", "_blank");
                }}
              >
                <TwitterXIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Phase 2 */}
          <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }}>
            <Typography
              variant="h5"
              sx={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              Useful links
            </Typography>
            <Grid
              container
              spacing={1}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              {links.map((link) => (
                <Grid item xs={6} key={link.text}>
                  <Link
                    href={link.href}
                    color="inherit"
                    underline="none" // Set underline to none
                    sx={{
                      "&:hover": {
                        color: "#bfbbbb",
                        cursor: "pointer",
                        transition: "color 0.3s",
                      },
                    }}
                  >
                    {link.text}
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Divider
        sx={{
          backgroundColor: "gray", // Customize the color
          marginLeft: "50px", // Add padding to the left
          marginRight: "50px",
          padding: 0, // Add padding to the right
          marginTop: { xs: "20px", sm: "0px" },
        }}
      />
      {/* Footer bottom */}
      <Box
        sx={{
          pt: 3,
          textAlign: "center",
        }}
      >
        <Typography variant="body2">
          Copyright © 2024 Aspire Software. All rights reserved.
        </Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          right: 16,
          top: -10,
        }}
      >
        <img
          src="https://www.aspiresoftware.com/wp-content/uploads/2023/06/award.png"
          alt="Certification Badge"
          style={{ height: 100 }}
        />
      </Box>
    </Box>
  );
};

export default Footer;
