import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./component/Home/Home";
import Main, { AuthMainLoader } from "./component/Main/Main";
import AboutUs, { AuthAboutUsLoader } from "./component/AboutUs/AboutUs";
import Admin from "./component/Admin/Admin";
import { AuthSettingsLoader } from "./Pages/AdminPage";
import ContactUs from "./component/ContactUs/ContactUs";
import Services from "./component/Services/Services";
import Careers from "./component/Careers/Careers";
import LoginAdmin from "./component/Admin/LoginAdmin";
import CareerDetail, { CarrersLoader } from "./component/Careers/CareerDetail";
import CareerApply from "./component/Careers/CareerApply";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import UpdatepassPage, {
  action as ResetPasswordAction,
} from "./Pages/UpdatepassPage";
import Error from "./component/Error/Error";
import SuccessSubmition from "./component/Success/SuccessSubmition";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      id: "root",
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: <Main />,
          loader: AuthMainLoader,
        },
        {
          path: "/Admin",
          element: <Admin />,
          loader: AuthSettingsLoader,
        },
        {
          path: "/WhoWeAre",
          element: <AboutUs />,
          loader: AuthAboutUsLoader,
        },
        {
          path: "/Services",
          element: <Services />,
        },
        {
          path: "/ContactUs",
          element: <ContactUs />,
        },
        {
          path: "/Careers-culture",
          element: <Careers />,
        },
        {
          path: "/Careers/:id",
          element: <CareerDetail />,
          loader: CarrersLoader,
        },
        {
          path: "/Careers/Apply/:id",
          element: <CareerApply />,
          loader: CarrersLoader,
        },
        {
          path: "/reset-password",
          element: <ResetPasswordPage />,
        },
        {
          path: "/new/reset-password/:token",
          element: <UpdatepassPage />,
          action: ResetPasswordAction,
        },
        {
          path: "/submission-success",
          element: <SuccessSubmition />,
        },
      ],
    },
    {
      path: "/Admin/Login",
      element: <LoginAdmin />,
    },
    {
      path: "/error",
      element: <Error />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
