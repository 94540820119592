import React, { useState, useRef } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { Form } from "react-router-dom";
import { addNewLeadershipMember } from "../../services/Admin.service";
import ImageUploader from "../Image/ImageUploader";

const LeadershipTeamManagement = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [picture, setPicture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const imageUploaderRef = useRef(null);
  const [errors, setErrors] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await addNewLeadershipMember(
        firstName,
        lastName,
        role,
        picture
      );

      if (response.ok) {
        // Clear the input fields
        setFirstName("");
        setLastName("");
        setRole("");
        setPicture(null);
        // Call removeFile function in ImageUploader
        imageUploaderRef.current.removeFile();
      } else {
        const data = await response.json();
        setErrors(data);
      }
    } catch (error) {
      console.error("Error adding leadership team member:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedFile = (selectedFile) => {
    setPicture(selectedFile);
  };

  return (
    <Form
      style={{ display: "flex", flexDirection: "column", width: "600px" }}
      method="Post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Typography
        variant="h5"
        sx={{
          color: "rgb(19, 32, 60)",
          textAlign: "center",
          mb: 2,
          fontWeight: "bold",
          padding: "10px 0px",
        }}
      >
        Leadership Team Management
      </Typography>
      <Stack spacing={3}>
        <TextField
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          variant="filled"
          fullWidth
        />
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          variant="filled"
          fullWidth
        />
        <TextField
          label="Role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          variant="filled"
          fullWidth
        />
        <ImageUploader
          getSelectedFile={handleSelectedFile}
          ref={imageUploaderRef}
        />
      </Stack>
      {errors &&
        errors.message.split(",").map((error, index) => (
          <Typography
            key={index}
            variant="p"
            color="error"
            sx={{ fontSize: "13px", fontWeight: "bold" }}
          >
            {error}
          </Typography>
        ))}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isLoading}
        sx={{ mt: 3, width: "100%" }}
      >
        {isLoading ? "Adding..." : "Add Leadership Team Member"}
      </Button>
    </Form>
  );
};

export default LeadershipTeamManagement;
