import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Snackbar,
  Alert, // Import Alert from Material-UI
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "lucide-react";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon from Material-UI
import "swiper/css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTheme, useMediaQuery } from "@mui/material";
import authHeader from "../../services/Auth-Loader";
import { deleteClient } from "../../services/Admin.service";

const ImageSlider = ({ data, setData, description }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const mediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const smallScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const swiperRef = useRef(null);
  const token = authHeader();

  const [openDialog, setOpenDialog] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // can be "success" or "error"
  });

  useEffect(() => {
    AOS.init();
  }, []);

  const handleDelete = async () => {
    try {
      const response = await deleteClient(companyToDelete, token);
      console.log("response", response);
      // const data = await response.json();

      // Update the state by filtering out the deleted company
      setData((prevData) =>
        prevData.filter((item) => item._id !== companyToDelete)
      );

      // Show success message
      setSnackbar({
        open: true,
        message: `Company has been deleted.`,
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting company:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete company. Please try again.",
        severity: "error",
      });
    } finally {
      setOpenDialog(false);
      setCompanyToDelete(null);
    }
  };

  const handleOpenDialog = (itemId) => {
    setCompanyToDelete(itemId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCompanyToDelete(null);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      sx={{
        padding: "30px 60px 60px 60px",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        borderRadius: "10px",
        boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
        position: "relative",
      }}
      data-aos="zoom-up"
      data-aos-duration="800"
    >
      <Box sx={{ textAlign: "start", marginBottom: "30px" }}>
        <Typography
          component={"h2"}
          variant={"h5"}
          sx={{
            fontSize: "27px",
            display: "flex",
            fontWeight: "bold",
            color: "rgb(19, 32, 60)",
          }}
          color={"Tertiary"}
          data-aos="fade-up"
          data-aos-duration="1100"
        >
          Who we work with ?
        </Typography>
      </Box>
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={20}
        slidesPerView={largeScreen ? 5 : mediumScreen ? 4 : smallScreen ? 2 : 1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "150px",
                  height: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_SERVER_API}${item?.Image}`}
                  alt={item.title}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Typography
                variant="subtitle2"
                align="center"
                fontWeight="bold"
                color={"primary"}
              >
                {item.Name}
              </Typography>
              {description && (
                <Typography variant="caption" align="center" color="quaternary">
                  {item.Description}
                </Typography>
              )}
              {token && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                  color="error"
                  onClick={() => handleOpenDialog(item._id)}
                >
                  <DeleteIcon size={20} />
                </IconButton>
              )}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <IconButton
        sx={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          color: "black",
          bgcolor: "rgba(255, 255, 255, 0.5)",
          "&:hover": {
            bgcolor: "rgba(255, 255, 255, 0.8)",
          },
        }}
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <ChevronLeft size={20} />
      </IconButton>
      <IconButton
        sx={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          color: "black",
          bgcolor: "rgba(255, 255, 255, 0.5)",
          "&:hover": {
            bgcolor: "rgba(255, 255, 255, 0.8)",
          },
        }}
        onClick={() => swiperRef.current?.slideNext()}
      >
        <ChevronRight size={20} />
      </IconButton>

      {/* Confirmation dialog for deletion */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {`Are you sure you want to delete this company?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ImageSlider;
