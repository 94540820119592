import React, { useEffect } from "react";
import { Typography, CardMedia, useMediaQuery, Avatar } from "@mui/material";
import { Quote } from "lucide-react";
import managerImage from "../../Image/CEO.png";
import AvatarManagerImage from "../../Image/Nabil-avatar.png";
import "./CeoMessage.css"; // Create this file for the custom styles
import AOS from "aos";
import "aos/dist/aos.css";
import { useTheme } from "@emotion/react";

const CeoMessage = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Typography
      variant="div"
      sx={{
        display: "flex",
        gap: "70px",
      }}
    >
      <Typography
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "50px",
        }}
      >
        <Typography
          component={"h2"}
          variant={"h5"}
          sx={{
            fontSize: "32px",
            display: "flex",
            fontWeight: "bold",
            justifyContent: { md: "start", xs: "center" },
          }}
          color={"secondary"}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          A message from our CEO
        </Typography>
        <p
          className="mx-4 text-lg font-sans tracking-wide leading-relaxed zoom-animation"
          style={{
            fontSize: "17px",
            fontFamily: "system-ui",
            letterSpacing: "1px",
            lineHeight: "2em",
            paddingRight: "60px",
            fontStyle: "italic",
          }}
        >
          <Quote
            size={18}
            style={{
              marginRight: "10px",
              transform: "rotate(180deg)",
              color: "#212529",
            }}
          />
          Create and adapt a working environment where we all can give our best
          and enjoy delivering it, the rest are details.
          <Quote
            style={{
              marginLeft: "10px",
            }}
            size={18}
          />
        </p>

        <Typography
          data-aos="fade-up"
          data-aos-duration="1000"
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
          }}
        >
          {!largeScreen && (
            <Avatar
              alt="Remy Sharp"
              src={AvatarManagerImage}
              sx={{ width: 65, height: 65 }}
            />
          )}

          {largeScreen ? (
            <>
              <Typography
                variant="span"
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                color={"primary"}
              >
                Nabil Mardelli
              </Typography>
              <Typography style={{ margin: 0, p: 0 }} color={"primary"}>
                GDC Country Manager
              </Typography>
            </>
          ) : (
            <div style={{ marginLeft: "20px" }}>
              <Typography
                variant="span"
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                color={"primary"}
              >
                Nabil Mardelli
              </Typography>
              <Typography style={{ margin: 0, p: 0 }} color={"primary"}>
                GDC Country Manager
              </Typography>
            </div>
          )}
        </Typography>
      </Typography>
      {/*Image*/}
      {largeScreen && (
        <Typography variant="div" style={{ flex: 1 }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CardMedia
              component="img"
              image={managerImage}
              alt="CEO"
              sx={{
                objectFit: "cover",
                width: "250px",
                height: "100%",
                borderRadius: "120px",
              }}
              data-aos="zoom-in"
              data-aos-duration="1000"
            />
          </div>
        </Typography>
      )}
    </Typography>
  );
};

export default CeoMessage;
