import React from "react";
import { Typography } from "@mui/material";
import CompanyInfoManagement from "./CompanyInfoManagement";
import ClientManagement from "./ClientManagement";
import LeadershipTeamManagement from "./LeadershipTeamManagement";
import TestimonialsSuccessStories from "./TestimonialsSuccessStories";
import Metrics from "./Metrics.js";
import PageTitle from "../PageTitle";

const Admin = () => {
  return (
    <>
      <PageTitle title={"Admin"} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "40px",
          flexDirection: "column",
        }}
      >
        <Typography
          component={"div"}
          sx={{
            backgroundColor: "#F8F8F8",
            borderRadius: "10px",
            display: "inline-block",
            justifyContent: "center",
            margin: "0 auto",
            boxShadow: "0 4px 8px rgba(170,170,170, 0.5)",
            padding: "0px 35px 20px 35px",
            mt: "20px",
            height: "100%",
          }}
        >
          <CompanyInfoManagement />
        </Typography>
        <Typography
          component={"div"}
          sx={{
            backgroundColor: "#F8F8F8",
            borderRadius: "10px",
            display: "inline-block",
            justifyContent: "center",
            margin: "0 auto",
            boxShadow: "0 4px 8px rgba(170,170,170, 0.5)",
            padding: "0px 35px 20px 35px",
            mt: "20px",
            height: "100%",
          }}
        >
          <ClientManagement />
        </Typography>
        <Typography
          component={"div"}
          sx={{
            backgroundColor: "#F8F8F8",
            borderRadius: "10px",
            display: "inline-block",
            justifyContent: "center",
            margin: "0 auto",
            boxShadow: "0 4px 8px rgba(170,170,170, 0.5)",
            padding: "0px 35px 20px 35px",
            mt: "20px",
            height: "100%",
          }}
        >
          <LeadershipTeamManagement />
        </Typography>
        <Typography
          component={"div"}
          sx={{
            backgroundColor: "#F8F8F8",
            borderRadius: "10px",
            display: "inline-block",
            justifyContent: "center",
            margin: "0 auto",
            boxShadow: "0 4px 8px rgba(170,170,170, 0.5)",
            padding: "0px 35px 20px 35px",
            mt: "20px",
            height: "100%",
          }}
        >
          <TestimonialsSuccessStories />
        </Typography>
        <Typography
          component={"div"}
          sx={{
            backgroundColor: "#F8F8F8",
            borderRadius: "10px",
            display: "inline-block",
            justifyContent: "center",
            margin: "0 auto",
            boxShadow: "0 4px 8px rgba(170,170,170, 0.5)",
            padding: "0px 35px 20px 35px",
            mt: "20px",
            height: "100%",
          }}
        >
          <Metrics />
        </Typography>
      </div>
    </>
  );
};

export default Admin;
