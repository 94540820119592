import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#212529",
    },
    secondary: {
      main: "rgb(0, 169, 184)",
    },
    Tertiary: {
      main: "rgb(19, 32, 60)",
    },
    quaternary: {
      main: "#4472C4",
    },
    quinary: {
      main: "#303130", // Color code here
    },
  },
});

export default theme;
