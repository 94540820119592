import { Typography } from "@mui/material";
import React from "react";

function StyledSmallFormComponent(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "50px",
        paddingBottom: "40px",
      }}
    >
      <Typography
        component={"div"}
        sx={{
          backgroundColor: "#F8F8F8",
          width: { xs: "25ch", md: "40ch", sm: "35ch" },
          borderRadius: "10px",
          justifyContent: "center",
          boxShadow: "0 4px 8px rgba(170,170,170, 0.5)",
          padding: "0px 35px 20px 35px",
        }}
      >
        {props.children}
      </Typography>
    </div>
  );
}

export default StyledSmallFormComponent;
