import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ImageSlider from "../Main/ImageSlider";
import TestimonialCard from "./TestimonialCard";
import Benefits from "./Benefits";
import PageTitle from "../PageTitle";

import { getClients, getTestimonialData } from "../../services/Admin.service";
import Loading from "../Loading/Loading";

const Services = () => {
  const [data, setData] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [isLoadingTestimonials, setIsLoadingTestimonials] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingClients(true); // Start loading for clients
      try {
        const response = await getClients();
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingClients(false); // Stop loading for clients
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchTestimonialData = async () => {
      setIsLoadingTestimonials(true); // Start loading for testimonials
      try {
        const response = await getTestimonialData();
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setTestimonialData(result);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingTestimonials(false); // Stop loading for testimonials
      }
    };
    fetchTestimonialData();
  }, []);

  return (
    <>
      <PageTitle title={"Services"} />
      <Typography
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "100px",
          justifyContent: "center",
          padding: {
            xs: "0px 30px ",
            md: "0px 50px",
            xl: "0px 150px",
          },
        }}
      >
        <Benefits />
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography
            component={"h2"}
            variant={"h5"}
            sx={{
              fontSize: "27px",
              display: "flex",
              color: "rgb(19, 32, 60)",
              fontWeight: "bold",
              justifyContent: { md: "start", xs: "center" },
            }}
          >
            Testimonials/Success stories
          </Typography>
          {isLoadingTestimonials ? (
            <Loading /> // Display loading component for testimonials
          ) : (
            <Grid container spacing={2} sx={{ mt: "5px" }}>
              {testimonialData.map((data, index) => (
                <Grid item xs={12} lg={6} key={index}>
                  <TestimonialCard data={data} setData={setTestimonialData} />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        <div style={{ marginBottom: "50px" }}>
          {isLoadingClients ? (
            <Loading /> // Display loading component for clients
          ) : (
            <ImageSlider data={data} setData={setData} description={true} />
          )}
        </div>
      </Typography>
    </>
  );
};

export default Services;
