import React from "react";
import Layout from "../Layout/Layout";
import { Typography, Button, Container, Box } from "@mui/material";
import PageTitle from "../PageTitle";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <PageTitle title={"Page not found"} />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "60vh",
          textAlign: "center",
        }}
      >
        <Box mb={2}>
          <Typography variant="h2" component="h1" fontWeight="bold">
            Hmm,
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" color="textSecondary">
            Looks like we can't find the page you're looking for
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/")}
          sx={{
            textTransform: "none",
            paddingX: 3,
            paddingY: 1,
            fontSize: "1rem",
            color: "white",
          }}
        >
          Return to homepage
        </Button>
      </Container>
    </Layout>
  );
};

export default Error;
