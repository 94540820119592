import ResetPassword from "../component/Admin/ResetPassword";
import PageTitle from "../component/PageTitle";
import StyledSmallFormComponent from "../component/StyledComponent/StyledSmallFormComponent";

function ResetPasswordPage() {
  return (
    <>
      <PageTitle title={`Reset Password`} />
      <StyledSmallFormComponent>
        <ResetPassword />
      </StyledSmallFormComponent>
    </>
  );
}

export default ResetPasswordPage;
