import React, { useEffect, useState } from "react";
import { Box, Typography, Container, Grid, Paper } from "@mui/material";
import { styled } from "@mui/system";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import CompanyHistory from "./CompanyHistory";
import LeadershipTeam from "./LeaderShipTeam";
import {
  getCompanyInfo,
  getLeaderShipTeam,
} from "../../services/Admin.service";
import AOS from "aos";
import "aos/dist/aos.css";
import PageTitle from "../PageTitle";
import Loading from "../Loading/Loading";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: theme.spacing(2),
  backgroundColor: "transparent",
  boxShadow: "none",
}));

const IconWrapper = styled(Box)(({ color }) => ({
  color: color,
  marginBottom: (theme) => theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const AboutUs = () => {
  const [companyInfo, setCompanyInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchCompanyInfo = async () => {
      setIsLoading(true);
      try {
        const response = await getCompanyInfo();
        if (response.ok) {
          const data = await response.json();
          setCompanyInfo(data);
        } else {
          console.error("Failed to fetch company info:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching company info:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };
    fetchCompanyInfo();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  const sections = [
    {
      icon: <RocketLaunchOutlinedIcon sx={{ fontSize: 50 }} />,
      title: "Our Mission",
      content: companyInfo?.Mission,
      color: "rgb(19, 32, 60)",
    },
    {
      icon: <LightbulbOutlinedIcon sx={{ fontSize: 50 }} />,
      title: "Our Vision",
      content: companyInfo?.Vision,
      color: "rgb(0, 169, 184)",
    },
    {
      icon: <DiamondOutlinedIcon sx={{ fontSize: 50 }} />,
      title: "Our Philosophy",
      content: companyInfo?.Philosophy,
      color: "#DF8977",
    },
  ];

  return (
    <>
      <PageTitle title={"About"} />
      <Typography
        sx={{
          margin: "20px 0px",
          display: "flex",
          flexDirection: "column",
          gap: "100px",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Typography
            sx={{
              padding: {
                xs: "0px 30px ",
                md: "0px 70px",
                xl: "0px 200px",
              },
            }}
            data-aos="zoom-up"
            data-aos-duration="1500"
          >
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255,0.7)",
                boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
                borderRadius: 2,
              }}
            >
              <h1
                style={{
                  fontFamily: "Titillium Web, sans-serif",
                  fontSize: "34px",
                  textAlign: "center",
                  padding: "20px 0px 0px 0px",
                }}
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                Mission, Vision and Values
              </h1>
              <Container maxWidth="xl" sx={{ padding: "30px" }}>
                <Grid container spacing={3} sx={{ padding: "10px" }}>
                  {sections.map((section, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <StyledPaper elevation={1}>
                        <IconWrapper color={section.color}>
                          {section.icon}
                        </IconWrapper>
                        <Box
                          sx={{
                            width: 60,
                            height: 2,
                            bgcolor: section.color,
                            mb: 3,
                            mx: "auto",
                          }}
                        />
                        <Typography
                          variant="h4"
                          component="h2"
                          gutterBottom
                          sx={{
                            color: section.color,
                            fontWeight: 700,
                            mb: 2,
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          {section.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{
                            lineHeight: 1.7,
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          {section.content}
                        </Typography>
                      </StyledPaper>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Box>
          </Typography>
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <Typography
            sx={{
              padding: {
                xs: "0px 30px ",
                md: "0px 70px",
                xl: "0px 200px",
              },
            }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <CompanyHistory companyHistory={companyInfo?.History} />
          </Typography>
        )}
        <LeadershipTeam />
      </Typography>
    </>
  );
};

export default AboutUs;

export const AuthAboutUsLoader = async ({ request, params }) => {
  const response = await getLeaderShipTeam();
  const leaderShipTeam = await response.json();

  return { leaderShipTeam };
};
