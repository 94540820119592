import React from "react";
import { Typography } from "@mui/material";

import "aos/dist/aos.css";
import WhyJoin from "./WhyJoin";
import OpenRoles from "./OpenRoles";
import PageTitle from "../PageTitle";

const Careers = () => {
  return (
    <>
      <PageTitle title={"Careers"} />
      <Typography
        component="div"
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "100px",
          justifyContent: "center",
          marginBottom: "30px",
          padding: {
            xs: "0px 30px",
            md: "0px 70px",
            xl: "0px 200px",
          },
        }}
      >
        <WhyJoin />
        <OpenRoles />
      </Typography>
    </>
  );
};

export default Careers;
