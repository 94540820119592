import React from "react";

import { redirect } from "react-router-dom";
import StyledSmallFormComponent from "../component/StyledComponent/StyledSmallFormComponent";
import Updatepass from "../component/Admin/Updatepass";
import { login, updatePassword } from "../services/Admin.service";
import PageTitle from "../component/PageTitle";

function UpdatepassPage() {
  return (
    <>
      <PageTitle title={"Update Password"} />
      <StyledSmallFormComponent>
        <Updatepass />
      </StyledSmallFormComponent>
    </>
  );
}

export default UpdatepassPage;

export const action = async ({ request, params }) => {
  const data = await request.formData();
  const password = data.get("password");
  const token = params.token;
  const response = await updatePassword(password, token);

  const responseData = await response.json();
  if (!response.ok) {
    return responseData;
  }
  const loginResponse = await login(responseData.Email, password);
  const loginData = await loginResponse.json();

  const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000;
  localStorage.setItem("expirationTime", expirationTime);
  localStorage.setItem("token", loginData.token);
  return redirect("/Admin");
};
