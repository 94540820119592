import React, { useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { Form, useLoaderData } from "react-router-dom";
import { updateCompanyInfo } from "../../services/Admin.service";

const CompanyInfoManagement = ({ initialData }) => {
  const data = useLoaderData();

  const [mission, setMission] = useState(data?.companyInfo?.Mission || "");
  const [vision, setVision] = useState(data?.companyInfo?.Vision || "");
  const [philosophy, setPhilosophy] = useState(
    data?.companyInfo?.Philosophy || ""
  );
  const [history, setHistory] = useState(data?.companyInfo?.History || "");
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    setIsLoading(true);

    try {
      const response = await updateCompanyInfo(
        history,
        philosophy,
        vision,
        mission
      );
      console.log("Update successful", response);
    } catch (error) {
      console.error("Error updating company info:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Form
      style={{ display: "flex", flexDirection: "column", width: "600px" }}
      method="Post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Typography
        variant="h5"
        sx={{
          color: "rgb(19, 32, 60)",
          textAlign: "center",
          mb: 2,
          fontWeight: "bold",
          padding: "10px 0px",
        }}
      >
        Organization Information
      </Typography>
      <Stack spacing={3}>
        <TextField
          label="Mission"
          multiline
          rows={4}
          value={mission}
          onChange={(e) => setMission(e.target.value)}
          variant="filled"
          fullWidth
        />
        <TextField
          label="Vision"
          multiline
          rows={4}
          value={vision}
          onChange={(e) => setVision(e.target.value)}
          variant="filled"
          fullWidth
        />
        <TextField
          label="Philosophy"
          multiline
          rows={4}
          value={philosophy}
          onChange={(e) => setPhilosophy(e.target.value)}
          variant="filled"
          fullWidth
        />
        <TextField
          label="History"
          multiline
          rows={6}
          value={history}
          onChange={(e) => setHistory(e.target.value)}
          variant="filled"
          fullWidth
        />
      </Stack>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isLoading}
        sx={{ mt: 3, width: "100%" }}
      >
        {isLoading ? "Updating..." : "Update Organization Info"}
      </Button>
    </Form>
  );
};

export default CompanyInfoManagement;
