import React, { useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { Business as BusinessIcon } from "@mui/icons-material";
import AOS from "aos";
import "aos/dist/aos.css";

export default function CompanyHistory({ companyHistory }) {
  // Split the companyHistory string into paragraphs
  const historyParagraphs = companyHistory
    ? companyHistory.split("\n").filter(Boolean)
    : [];

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "rgba(0, 169, 184,0.6)",
        color: "white",
        padding: 4,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <BusinessIcon sx={{ fontSize: 40, marginRight: 2 }} />
        <Typography variant="h4" component="h2" sx={{ fontWeight: "bold" }}>
          Company History
        </Typography>
      </Box>
      <Box sx={{ lineHeight: 1.8, marginTop: "20px" }}>
        {historyParagraphs.map((paragraph, index) => (
          <Typography key={index} variant="body1" paragraph>
            {paragraph}
          </Typography>
        ))}
      </Box>
    </Paper>
  );
}
