import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  CircularProgress,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";
import { useTheme, useMediaQuery } from "@mui/material";
const OpenRoles = () => {
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Function to build query string from filters
  const buildQueryString = (departments) => {
    if (!departments.length) return "";
    return departments
      .map((dept) => `departments=${encodeURIComponent(dept)}`)
      .join("&");
  };

  // Function to fetch roles with filters
  const fetchRoles = async (queryString = "") => {
    setLoading(true);
    setError(null);

    try {
      const baseUrl =
        "https://api-gdcleb.aspire.software/Recruitment/gdc-web-pages/in-progress";
      const url = queryString ? `${baseUrl}?${queryString}` : baseUrl;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setRoles(data);

      if (!queryString) {
        const uniqueDepartments = [
          ...new Set(data.map((role) => role.Department)),
        ];
        setDepartments(uniqueDepartments);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      setError("Failed to load open positions. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleDepartmentChange = (department) => {
    const updatedDepartments = selectedDepartments.includes(department)
      ? selectedDepartments.filter((dep) => dep !== department)
      : [...selectedDepartments, department];

    setSelectedDepartments(updatedDepartments);
    const queryString = buildQueryString(updatedDepartments);
    fetchRoles(queryString);
  };

  const navigateHandler = (id) => {
    navigate(`/Careers/${id}`);
  };

  return (
    <Box sx={{ display: "flex", gap: 3 }}>
      {/* Filter Section */}
      {!isMobile && (
        <Paper
          elevation={3}
          sx={{
            p: 2,
            width: 280,
            flexShrink: 0,
            height: "fit-content",
            position: "sticky",
            top: 20,
          }}
          data-aos="fade-right" // Animation for filter section
          data-aos-duration="500" // Duration of animation
        >
          <Typography
            variant="h6"
            color="primary"
            sx={{ fontSize: "22px", fontWeight: "bold" }}
          >
            Filter by:
          </Typography>

          <Accordion
            defaultExpanded
            sx={{
              width: "100%",
              backgroundColor: "white",
              "&.Mui-expanded": {
                backgroundColor: "white",
              },
              "&:before": {
                display: "none",
              },
              boxShadow: "none",
              border: "none",
            }}
            data-aos="fade-up" // Animation for accordion
            data-aos-duration="500" // Duration of animation
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              sx={{
                minHeight: "48px !important",
                backgroundColor: "white",
                boxShadow: "none",
                paddingBottom: "0 !important",
              }}
            >
              <Typography
                color="primary"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              >
                Departments
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: 2,
                  }}
                >
                  <CircularProgress size={24} />
                  <Typography
                    variant="body2"
                    sx={{
                      marginLeft: 1,
                      fontWeight: "bold",
                      color: "text.secondary",
                      fontStyle: "italic",
                    }}
                  >
                    Loading departments...
                  </Typography>
                </Box>
              ) : (
                <FormGroup sx={{ width: "100%" }}>
                  {departments?.map((department) => (
                    <FormControlLabel
                      key={department}
                      sx={{
                        width: "100%",
                        margin: 0,
                      }}
                      control={
                        <Checkbox
                          checked={selectedDepartments.includes(department)}
                          onChange={() => handleDepartmentChange(department)}
                          size="small"
                          disabled={loading}
                        />
                      }
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            width: "100%",
                          }}
                        >
                          <Typography noWrap>{department}</Typography>
                        </Box>
                      }
                    />
                  ))}
                </FormGroup>
              )}
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}

      {/* Rest of the component remains the same */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="h5"
          component="h3"
          color="primary"
          sx={{
            marginBottom: 4,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
          data-aos="fade-up" // Animation for title
          data-aos-duration="500" // Duration of animation
        >
          Open Positions {!loading && `(${roles?.length || 0})`}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 8 }}>
            <Loading />
          </Box>
        ) : (
          <>
            <Grid container spacing={3}>
              {roles.map((role, index) => (
                <Grid item xs={12} sm={6} xl={4} key={index}>
                  <Card
                    sx={{
                      height: "100%",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: 4,
                      },
                    }}
                    data-aos="fade-up"
                    data-aos-duration="800" // Duration of animation
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        {role.JobTitle}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mb: 2,
                        }}
                      >
                        <WorkIcon fontSize="small" color="action" />
                        {role.Department}
                      </Box>

                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        sx={{
                          mt: 1,
                          color: "white",
                          width: "150px",
                          height: "35px",
                        }}
                        onClick={() => navigateHandler(role._id)}
                      >
                        View Job
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {roles.length === 0 && !loading && !error && (
              <Box
                sx={{
                  textAlign: "center",
                  py: 4,
                  color: "text.secondary",
                }}
              >
                <Typography variant="h6">
                  No positions found matching your criteria
                </Typography>
                <Typography variant="body2">
                  Try adjusting your filters to see more results
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default OpenRoles;
