import React from "react";
import { Box, Typography } from "@mui/material";

const GoogleMapIntergration = () => {
  // Function to handle redirection based on device type
  // const handleMapRedirect = () => {
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   const googleMapUrl = isMobile
  //     ? "https://maps.google.com/?q=33.87640958065454,35.529436615201254"
  //     : "https://www.google.com/maps?q=33.87640958065454,35.529436615201254";

  //   window.open(googleMapUrl, "_blank");
  // };

  return (
    <div>
      <Typography
        variant="h4"
        component="h2"
        sx={{ marginBottom: 4, fontWeight: "bold" }}
        color="primary"
        data-aos="fade-down"
      >
        Our Location
      </Typography>
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          borderRadius: "10px",
          boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
          position: "relative",
          width: "100%", // Full width for responsiveness
          margin: "auto",
          mt: 5,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "relative",
            paddingBottom: "40.25%", // 16:9 aspect ratio (same as before)
            height: 0,
            overflow: "hidden",
            "& iframe": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: 0,
            },
            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.8949834039578!2d35.529436615201254!3d33.87640958065454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17304b9bedf1%3A0xb21e19e50c950a09!2sAspire%20Software%20Lebanon!5e0!3m2!1sen!2sus!4v1666102234920!5m2!1sen!2sus"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Aspire Software Lebanon Location"
          />
        </Box>

        {/* Button for Redirection */}
        {/* <Button
          variant="contained"
          onClick={handleMapRedirect}
          sx={{
            backgroundColor: "#1976D2",
            color: "#fff",
            display: "block",
            width: "100%",
            marginTop: "2px",
            padding: "10px 0",
            fontSize: "18px",
            textTransform: "none",
            boxShadow: "0 8px 20px rgba(25, 118, 210, 0.3)",
            "&:hover": {
              backgroundColor: "#1565C0",
            },
          }}
        >
          Open in Google Maps
        </Button> */}
      </Box>
    </div>
  );
};

export default GoogleMapIntergration;
