import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Ensure react-router-dom is installed and imported
import { IconButton, Box, Typography, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useSelector } from "react-redux";

const SuccessSubmition = () => {
  const open = useSelector((state) => state.Success.success);
  const navigate = useNavigate();

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
  };

  useEffect(() => {
    if (!open) {
      // Redirect to error page if open is false
      navigate("/error"); // Replace "/error" with your actual error page route
    }
  }, [open, navigate]);

  return (
    <Box style={containerStyle}>
      <IconButton color="success" sx={{ mb: 2 }}>
        {" "}
        {/* Add bottom margin */}
        <CheckCircleOutlineIcon fontSize="large" />
      </IconButton>
      <Typography component="h4" variant="h4" color="primary" sx={{ mb: 1 }}>
        {" "}
        {/* Add bottom margin */}
        Thank You for Submitting!
      </Typography>
      <Typography component="h6" variant="h6" color="primary" sx={{ mb: 3 }}>
        {" "}
        {/* Add bottom margin */}
        Your submission has been received.
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate("/Careers-culture")}
        sx={{
          textTransform: "none",
          paddingX: 3,
          paddingY: 1,
          fontSize: "1rem",
          color: "white",
          mb: 2, // Add bottom margin
        }}
      >
        Return to Careers Page
      </Button>
    </Box>
  );
};

export default SuccessSubmition;
