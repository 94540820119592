import React, { useState } from "react";
import { TextField, InputAdornment, Typography } from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Form, useActionData, useNavigation } from "react-router-dom";
import StyledButtonComponent from "../StyledComponent/StyledButtonComponent";

function Updatepass() {
  const navigate = useNavigation();
  const submiting = navigate.state === "submitting";
  const [isPasswordFocused, setisPasswordFocused] = useState(false);
  const data = useActionData();
  const handlePasswordFocus = () => {
    setisPasswordFocused(true);
  };
  const handlePasswordBlur = () => {
    setisPasswordFocused(false);
  };
  return (
    <Form style={{ display: "flex", flexDirection: "column" }} method="Post">
      <h2 style={{ color: "rgb(19, 32, 60)", textAlign: "center" }}>
        Update Password{" "}
      </h2>
      <TextField
        name="password"
        label="New Password"
        id="outlined-start-adornment"
        sx={{ m: "10px 0 0 0", width: { xs: "25ch", md: "40ch", sm: "35ch" } }}
        variant="filled"
        InputProps={{
          inputProps: {
            style: {
              fontSize: "16px",
              fontWeight: 600, // Change the font size as needed
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              {
                <VpnKeyIcon
                  style={{
                    color: isPasswordFocused ? "rgb(19, 32, 60)" : "inherit",
                  }}
                />
              }
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { fontWeight: 600, fontSize: "17px" },
        }}
        type="password"
        onFocus={handlePasswordFocus}
        onBlur={handlePasswordBlur}
      />
      {data &&
        data.message.split(",").map((error, index) => (
          <Typography
            key={index}
            variant="p"
            color="error"
            sx={{ fontSize: "13px", fontWeight: "bold" }}
          >
            {error}
          </Typography>
        ))}
      <StyledButtonComponent
        submiting={submiting}
        title="Update"
        submitTitle="Updating..."
      />
    </Form>
  );
}

export default Updatepass;
