import React from "react";
import { Box, Typography, Container, Button } from "@mui/material";
import img from "../../Image/Careers-Background.jpg";
import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
const CareersHeader = ({ roles }) => {
  const navigate = useNavigate();
  const redirectHandler = () => {
    navigate("/Careers-culture");
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "300px",
        position: "relative",
        overflow: "hidden",
        borderRadius: "10px",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          position: "relative",
          zIndex: 2,
          paddingLeft: "0px", // Added padding to move the text to the left
        }}
      >
        <Box>
          <Typography
            variant="h4"
            sx={{
              color: "white",
              fontWeight: "bold",
              mb: 1,
            }}
          >
            {roles.JobTitle}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              mt: 1,
              color: "white",
              width: "210px",
              height: "35px",
            }}
            onClick={redirectHandler}
          >
            <ArrowRightAltIcon
              style={{ transform: "rotate(180deg)", margin: 0, padding: 0 }}
            />{" "}
            <span style={{ marginLeft: "4px" }}>Back to open Roles</span>
          </Button>
        </Box>
      </Container>
      <Box
        component="img"
        src={img}
        alt="Office workspace"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
    </Box>
  );
};

export default CareersHeader;
