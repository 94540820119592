import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Form, Link, useNavigate, useNavigation } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { login } from "../../services/Admin.service";
import PageTitle from "../PageTitle";

function LoginAdmin() {
  const navigate = useNavigation();
  const redirect = useNavigate();
  const submitting = navigate.state === "submitting";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleEmailFocus = () => {
    setIsEmailFocused(true);
  };

  const handleEmailBlur = () => {
    setIsEmailFocused(false);
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    try {
      const response = await login(email, password);
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.token);

        // Calculate the expiration time (2 hours from now)
        const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000; // 2 hours in milliseconds
        localStorage.setItem("expirationTime", expirationTime);

        redirect("/");
      } else {
        setErrors(data);
      }
    } catch (error) {
      setErrors([{ message: "An error occurred. Please try again." }]);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <>
      <PageTitle title={"Admin-Login"} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "70px",
          paddingBottom: "40px",
        }}
      >
        <Typography
          component={"div"}
          sx={{
            backgroundColor: "#F8F8F8",
            width: { xs: "25ch", md: "40ch", sm: "35ch" },
            borderRadius: "10px",
            justifyContent: "center",
            boxShadow: "0 4px 8px rgba(170,170,170, 0.5)",
            padding: "0px 35px 20px 35px",
          }}
        >
          <Form
            style={{ display: "flex", flexDirection: "column" }}
            method="Post"
            onSubmit={submitHandler}
          >
            <h2 style={{ color: "rgb(19, 32, 60)", textAlign: "center" }}>
              Login
            </h2>
            <TextField
              name="Email"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              sx={{
                m: "10px 0 0 0",
                width: { xs: "25ch", md: "40ch", sm: "35ch" },
              }}
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon
                      style={{
                        color: isEmailFocused ? "rgb(19, 32, 60)" : "inherit",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { fontWeight: 600, fontSize: "17px" },
              }}
              onFocus={handleEmailFocus}
              onBlur={handleEmailBlur}
            />
            <TextField
              name="password"
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              sx={{
                m: "10px 0 5px 0",
                width: { xs: "25ch", md: "40ch", sm: "35ch" },
              }}
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyIcon
                      style={{
                        color: isPasswordFocused
                          ? "rgb(19, 32, 60)"
                          : "inherit",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { fontWeight: 600, fontSize: "17px" },
              }}
              type="password"
              onFocus={handlePasswordFocus}
              onBlur={handlePasswordBlur}
            />
            <Link to="/reset-password" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  color: "rgba(77, 101, 153, 0.8)",
                  fontSize: "12px",
                  fontWeight: 700,
                  cursor: "pointer",
                  mt: "5px",
                }}
              >
                Forgot password ?
              </Typography>
            </Link>
            {errors &&
              errors?.message?.split(",").map((error, index) => (
                <Typography
                  key={index}
                  variant="p"
                  color="error"
                  sx={{ fontSize: "13px", fontWeight: "bold" }}
                >
                  {error}
                </Typography>
              ))}
            <Button
              type="submit"
              sx={{ mt: "15px", fontWeight: 500 }}
              color="primary"
              variant="contained"
              disabled={submitting || isLoading}
            >
              {isLoading ? "Logging In..." : "Log In"}
            </Button>
          </Form>
        </Typography>
      </div>
    </>
  );
}

export default LoginAdmin;
