import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  IconButton,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import { useLoaderData } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import DeleteIcon from "@mui/icons-material/Delete";
import authHeader from "../../services/Auth-Loader";
import { deleteLeadershipTeamMember } from "../../services/Admin.service";

const cardColors = [
  "rgba(19, 32, 60, 0.5)",
  "rgba(0, 169, 184, 0.5)",
  "rgba(223, 137, 119, 0.5)",
  "rgba(48, 49, 48, 0.5)",
];

export default function LeadershipTeam() {
  const initialData = useLoaderData();
  const token = authHeader();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [teamMembers, setTeamMembers] = useState(
    initialData?.leaderShipTeam || []
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const handleDeleteClick = (member) => {
    setSelectedMember(member);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedMember(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteLeadershipTeamMember(selectedMember._id, token);

      // Update the state by filtering out the deleted member
      setTeamMembers((prevMembers) =>
        prevMembers.filter((member) => member._id !== selectedMember._id)
      );

      // Show success message
      setSnackbar({
        open: true,
        message: `${selectedMember.FirstName} ${selectedMember.LastName} has been removed from the team.`,
        severity: "success",
      });

      handleCloseDialog();
    } catch (error) {
      console.error("Error deleting team member:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete team member. Please try again.",
        severity: "error",
      });
    }
  };

  return (
    <Box sx={{ padding: "0px 70px" }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{ marginBottom: 4, fontWeight: "bold" }}
        color="primary"
        data-aos="fade-down"
      >
        Our Leadership Team
      </Typography>
      <Grid container spacing={6}>
        {teamMembers.map((member, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={member._id}>
              <Card
                sx={{
                  height: 400,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  boxShadow: "none",
                  backgroundColor: "#f5f5f5",
                  borderTopLeftRadius: "50px",
                  borderTopRightRadius: "2px",
                  borderBottomLeftRadius: "2px",
                  borderBottomRightRadius: "50px",
                  position: "relative",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  },
                }}
                data-aos="zoom-in"
                data-aos-delay={index * 100}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_SERVER_API}${member?.Image}`}
                    alt={member.FirstName}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center top",
                      transition: "transform 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                    data-aos="fade-zoom-in"
                    data-aos-delay={index * 100 + 100}
                    data-aos-duration="800"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: cardColors[index % 4],
                      padding: 2,
                    }}
                    data-aos="fade-up"
                    data-aos-delay={index * 100 + 200}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        marginBottom: 0.5,
                        color: "white",
                      }}
                    >
                      {member.FirstName} {member.LastName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                    >
                      {member.Role}
                    </Typography>
                  </Box>

                  {token && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <Badge color="error">
                        <IconButton
                          onClick={() => handleDeleteClick(member)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Badge>
                    </Box>
                  )}
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {selectedMember?.FirstName}{" "}
            {selectedMember?.LastName} from the leadership team?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
