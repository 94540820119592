import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useNavigate } from "react-router-dom";

const HexagonDecoration = ({ scrollY }) => {
  const rotate = scrollY * 0.2; // Adjust this value to control the rotation speed

  return (
    <svg
      width="200"
      height="180"
      viewBox="0 0 70 70"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${rotate}deg)`, // Apply rotation
        transition: "transform 0.3s ease-out",
      }}
    >
      <polygon
        points="75,50 62.5,71.6 37.5,71.6 25,50 37.5,28.4 62.5,28.4"
        fill="none"
        stroke="#4287f5"
        strokeWidth="3"
        transform="translate(0,0) scale(0.55)"
      />
      <polygon
        points="75,50 62.5,71.6 37.5,71.6 25,50 37.5,28.4 62.5,28.4"
        fill="none"
        stroke="#42c5f5"
        strokeWidth="3"
        transform="translate(25,15) scale(0.55)"
      />
      <polygon
        points="75,50 62.5,71.6 37.5,71.6 25,50 37.5,28.4 62.5,28.4"
        fill="none"
        stroke="#ff7eb3"
        strokeWidth="3"
        transform="translate(0,30) scale(0.55)"
      />
    </svg>
  );
};

const FormattedText = ({ text }) => {
  const formatText = (content) => {
    if (!content) return "";

    return (
      content
        // Handle numbered lists
        .replace(/(?:^\d+\.\s(.+$)\n?)+/gm, (match) => {
          const items = match
            .split("\n")
            .filter((item) => item.trim())
            .map((item) => item.replace(/^\d+\.\s/, ""))
            .map((item) => `<li>${item}</li>`)
            .join("");
          return `<ol>${items}</ol>`;
        })
        // Handle bullet lists
        .replace(/(?:^-\s(.+$)\n?)+/gm, (match) => {
          const items = match
            .split("\n")
            .filter((item) => item.trim())
            .map((item) => item.replace(/^-\s/, ""))
            .map((item) => `<li>${item}</li>`)
            .join("");
          return `<ul>${items}</ul>`;
        })
        // Handle bold text
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        // Handle italic text
        .replace(/\*(.*?)\*/g, "<em>$1</em>")
        // Handle underline text
        .replace(/__(.*?)__/g, "<u>$1</u>")
        // Handle line breaks
        .replace(/\n/g, "<br />")
    );
  };

  return (
    <Typography
      component="div"
      dangerouslySetInnerHTML={{
        __html: formatText(text),
      }}
      sx={{
        "& strong": {
          fontWeight: "bold",
        },
        "& em": {
          fontStyle: "italic",
        },
        "& u": {
          textDecoration: "underline",
        },
        "& ul, & ol": {
          marginLeft: 2,
          marginTop: 1,
          marginBottom: 1,
          paddingLeft: 2,
        },
        "& li": {
          marginBottom: 0.5,
        },
      }}
    />
  );
};

const CareerDetailBody = ({ roles }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [scrollY, setScrollY] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const translateY = scrollY * 0.6; // Control vertical movement speed

  const redirectHandler = () => {
    navigate(`/Careers/Apply/${roles._id}`);
  };
  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
        borderRadius: "10px",
        padding: {
          xs: theme.spacing(3),
          sm: theme.spacing(4),
          md: theme.spacing(5),
        },
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Box style={{ maxWidth: "1000px" }}>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              marginBottom: "1.2rem",
              fontSize: { xs: "1.5rem", md: "2rem" },
              fontWeight: "bold",
            }}
          >
            <span>Job Openings</span>
            <span style={{ margin: "0 8px" }}> {">"} </span>
            <span>{roles.JobTitle}</span>
          </Typography>

          <Typography
            variant={isMobile ? "h6" : "h5"}
            component="h2"
            sx={{
              fontWeight: "bold",
              marginBottom: theme.spacing(3),
              color: theme.palette.text.primary,
            }}
          >
            Description
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(3),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              <ApartmentIcon color="primary" />
              <Typography>{roles.EmploymentStatus}</Typography>
            </Box>

            <Typography
              sx={{
                color: "#212529",
                lineHeight: 1.6,
                fontSize: {
                  xs: "0.875rem",
                  sm: "16px",
                },
                fontFamily: '"System UI", "Segoe UI", "APOEL", sans-serif',
              }}
            >
              <FormattedText text={roles.Description} />
            </Typography>
          </Box>
        </Box>
        {!isMobile && (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ color: "white" }}
                onClick={redirectHandler}
              >
                Apply To Position
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                transform: `translateY(${translateY}px)`, // Apply vertical movement
                transition: "transform 0.3s ease-out",
              }}
            >
              <HexagonDecoration scrollY={scrollY} />
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default CareerDetailBody;
