import { Box } from "@mui/material";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const MainSection = ({ style, children }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Box
      sx={{
        ...style, // Apply the style prop to the Box component
      }}
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      {children}
    </Box>
  );
};

export default MainSection;
