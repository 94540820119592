import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Badge, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "./ImageUploader.css";

const ImageUploader = forwardRef(({ name, getSelectedFile }, ref) => {
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file);
      getSelectedFile && getSelectedFile(file);
    }
  };

  const removeFile = (e) => {
    if (e) e.stopPropagation();
    setSelectedFile(null);
    getSelectedFile && getSelectedFile(null);
    if (inputRef.current) inputRef.current.value = null;
  };

  useImperativeHandle(ref, () => ({
    removeFile,
  }));

  return (
    <Typography
      component="div"
      color="primary"
      sx={{
        backgroundColor: "#eeeeee",
        border: "2px dashed #eeeeee",
        borderRadius: "10px",
        marginTop: "10px",
        padding: "20px 0px",
        textAlign: "center",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: { xs: "25ch", sm: "100%" },
        flexDirection: { sm: "row", xs: "column" },
      }}
    >
      <input
        style={{
          cursor: "pointer",
          display: "flex",
          width: "13ch",
          overflow: "hidden",
        }}
        type="file"
        name={name}
        onChange={handleFileChange}
        ref={inputRef}
        accept="image/*"
        aria-label="Upload Image"
      />
      <Typography component={"div"} mt={"4px"}>
        {selectedFile && (
          <Badge
            badgeContent={
              <CancelIcon
                onClick={removeFile}
                sx={{ fontSize: "30px", cursor: "pointer" }}
              />
            }
          >
            <Typography
              component={"img"}
              src={URL.createObjectURL(selectedFile)}
              alt="Uploaded"
              sx={{
                width: { sm: "100px", xs: "60px" },
                height: { sm: "100px", xs: "60px" },
                objectFit: "cover",
                borderRadius: "10px",
                marginLeft: "5px",
              }}
            />
          </Badge>
        )}
      </Typography>
    </Typography>
  );
});

export default ImageUploader;
