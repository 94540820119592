import { Button } from "@mui/material";
import React from "react";

function StyledButtonComponent(props) {
  return (
    <Button
      type="submit"
      sx={{ mt: "15px", fontWeight: 500 }}
      color="primary"
      variant="contained"
      disabled={props.submiting}
    >
      {props.submiting ? props.submitTitle : props.title}
    </Button>
  );
}

export default StyledButtonComponent;
