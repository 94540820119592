import React from "react";
import { IconButton, Box, Typography, Paper } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";

const AnimatedArrow = ({
  toSubHeaderOne,
  isOpen,
  subHeaderOne,
  subHeaderTwo,
  subHeaderThree,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      position="relative"
      padding={0}
      margin={0}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <IconButton
        sx={{
          transition: "transform 0.5s ease-in-out",
          transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
          p: 0,
          "&:hover": {
            background: "none",
          },
        }}
      >
        <KeyboardArrowDownIcon
          sx={{
            fontSize: "1.5rem",
            color: isOpen ? "gray" : "primary.main",
          }}
        />
      </IconButton>
      <Paper
        elevation={2}
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          top: "100%",
          left: 0,
          padding: "20px",
          zIndex: 1,
          backgroundColor: "white",
          transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
          opacity: isOpen ? 1 : 0,
          transform: isOpen ? "translateY(0)" : "translateY(20px)",
          pointerEvents: isOpen ? "auto" : "none",
          whiteSpace: "nowrap",
          borderRadius: "10px",
          marginTop: "2px",
        }}
      >
        {subHeaderOne ? (
          <Typography
            variant="body1"
            sx={{
              padding: "10px",
              "&:hover": {
                color: "#4472D8",
                boxShadow: 1,
                borderRadius: "3px",
                cursor: "pointer",
                transition: "all 0.2s ease-in-out",
              },
            }}
            onClick={() => navigate(toSubHeaderOne)}
          >
            {subHeaderOne}
          </Typography>
        ) : null}
        {subHeaderTwo ? (
          <Typography
            variant="body1"
            sx={{
              padding: "10px",
              "&:hover": {
                color: "#4472D8",
                boxShadow: 1,
                borderRadius: "3px",
                cursor: "pointer",
                transition: "all 0.2s ease-in-out",
              },
            }}
          >
            {subHeaderTwo}
          </Typography>
        ) : null}
        {subHeaderThree ? (
          <Typography
            variant="body1"
            sx={{
              padding: "10px",
              "&:hover": {
                color: "#4472D8",
                boxShadow: 1,
                borderRadius: "3px",
                cursor: "pointer",
                transition: "all 0.2s ease-in-out",
              },
            }}
          >
            {subHeaderThree}
          </Typography>
        ) : null}
      </Paper>
    </Box>
  );
};

export default AnimatedArrow;
