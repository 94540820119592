import { createSlice, configureStore } from "@reduxjs/toolkit";
const SuccessSlice = createSlice({
  name: "Success",
  initialState: { success: false },
  reducers: {
    toggle(state) {
      state.success = true;
    },
  },
});

export const SuccessAction = SuccessSlice.actions;
const store = configureStore({
  reducer: {
    Success: SuccessSlice.reducer,
  },
});

export default store;
