import React, { useEffect } from "react";
import { Box, Typography, Container } from "@mui/material";
import img from "../../Image/Contact-Us-Heading.png";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactUsHeader = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: { xs: "200px", sm: "250px", md: "300px" }, // Responsive height
        position: "relative",
        overflow: "hidden",
        borderRadius: "10px",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "start" }, // Centered on small screens
          textAlign: { xs: "center", md: "left" }, // Center text alignment on small screens
          position: "relative",
          zIndex: 2,
          paddingX: { xs: 2, md: 0 }, // Add padding on small screens
        }}
      >
        <Box sx={{ ml: { xs: 0, md: 3, lg: 5 } }}>
          {" "}
          {/* Adjusted margin-left for md and lg */}
          <Typography
            variant="h4"
            data-aos="fade-up"
            data-aos-duration="800"
            sx={{
              color: "secondary.main",
              fontWeight: "bold",
              mb: 1,
              fontSize: { xs: "1.5rem", md: "2rem" }, // Responsive font size
            }}
          >
            Contact us
          </Typography>
          <Typography
            variant="h2"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: { xs: "1.8rem", sm: "2.5rem", md: "3rem" }, // Adjusted font size for responsiveness
            }}
          >
            Let's keep in touch
          </Typography>
        </Box>
      </Container>
      <Box
        component="img"
        src={img}
        alt="Office workspace"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          zIndex: 1,
        }}
      />
    </Box>
  );
};

export default ContactUsHeader;
