import React, { useState, useRef } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { Form } from "react-router-dom";
import { addNewClient } from "../../services/Admin.service";
import ImageUploader from "../Image/ImageUploader";

const ClientManagement = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState();
  const imageUploaderRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      console.log(name, image, description);
      const response = await addNewClient(name, image, description);
      console.log("Update successful", response);
      if (response.ok) {
        // Clear the input fields
        setName("");
        setDescription("");
        setImage(null);
        setErrors("");
        // Call removeFile function in ImageUploader
        imageUploaderRef.current.removeFile();
      } else {
        const data = await response.json();
        setErrors(data);
      }
    } catch (error) {
      console.error("Error updating company info:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedFile = (selectedFile) => {
    setImage(selectedFile);
  };

  return (
    <Form
      style={{ display: "flex", flexDirection: "column", width: "600px" }}
      method="Post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Typography
        variant="h5"
        sx={{
          color: "rgb(19, 32, 60)",
          textAlign: "center",
          mb: 2,
          fontWeight: "bold",
          padding: "10px 0px",
        }}
      >
        Organization Clients
      </Typography>
      <Stack spacing={3}>
        <TextField
          label="Company Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="filled"
          fullWidth
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          variant="filled"
          fullWidth
          multiline
          rows={4}
        />
        <ImageUploader
          getSelectedFile={handleSelectedFile}
          ref={imageUploaderRef}
        />
      </Stack>
      {errors &&
        errors.message.split(",").map((error, index) => (
          <Typography
            key={index}
            variant="p"
            color="error"
            sx={{ fontSize: "13px", fontWeight: "bold" }}
          >
            {error}
          </Typography>
        ))}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isLoading}
        sx={{ mt: 3, width: "100%" }}
      >
        {isLoading ? "Updating..." : "Add Clients"}
      </Button>
    </Form>
  );
};

export default ClientManagement;
