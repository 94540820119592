import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Typography,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme, useMediaQuery } from "@mui/material";
import NavLinkHeader from "./NavLinkHeader";
import StyledButton from "../StyledComponent/StyledButton";
import img from "../../Image/Aspire-Logo.png";
import authHeader from "../../services/Auth-Loader";

export default function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const token = authHeader();
  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const navLinks = (
    <>
      <NavLinkHeader title={"ABOUT US"} toSubHeaderOne={"WhoWeAre"} />
      <NavLinkHeader title={"SERVICES"} toSubHeaderOne={"services"} />
      <NavLinkHeader
        // to={"/About"}
        title={"CAREERS"}
        toSubHeaderOne={"Careers-culture"}
        // subHeaderOne={"LinkedIn Profile jobs section"}
        // subHeaderTwo={"Aspire Software Open roles"}
        // subHeaderThree={"Why Join?"}
      />
      {/* <NavLinkHeader toSubHeaderOne={"ContactUs"} title={"CONTACT US"} /> */}
      {token && <NavLinkHeader toSubHeaderOne={"Admin"} title={"ADMIN"} />}
    </>
  );

  return (
    <Typography
      sx={{
        padding: {
          xs: "30px 30px 0px 30px",
          md: "30px 100px 0px 100px",
          xl: "30px 250px 0px 250px",
        },
      }}
    >
      <nav
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "100px" }}>
          <Link to="/">
            <Typography
              component={"img"}
              variant="img"
              src={img}
              alt="Logo"
              sx={{ width: "180px", height: "75px" }}
            />
          </Link>
          {largeScreen && (
            <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
              {navLinks}
            </div>
          )}
        </div>
        {!largeScreen && (
          // Small Screen Menu Button
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        )}
        {/* Styled Button */}
        {largeScreen && (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => navigate("/ContactUs")}
          >
            <StyledButton name="Contact US" />
          </div>
        )}
      </nav>

      {/* Drawer for Small Screens */}
      {/* Drawer for Small Screens */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List sx={{ width: 250 }} onClick={toggleDrawer(false)}>
          {[
            { text: "ABOUT US", route: "/WhoWeAre" },
            { text: "SERVICES", route: "/services" },
            { text: "CAREERS", route: "/Careers-culture" },
            { text: "CONTACT US", route: "/ContactUs" },
            ...(token ? [{ text: "ADMIN", route: "/Admin" }] : []), // Conditionally add "ADMIN" item
          ].map(({ text, route }) => (
            <ListItem button key={text}>
              <Link
                to={route}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemText primary={text} />
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Typography>
  );
}
