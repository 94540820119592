import authHeader from "./Auth-Loader";

const API_URL = process.env.REACT_APP_SERVER_API;

export const login = (Email, password) => {
  return fetch(API_URL + "/Admin/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ Email, password }),
  }).catch((error) => {
    console.error("Error occurred :", error.message);
    throw error;
  });
};
export const updatePassword = (password, token) => {
  return fetch(API_URL + "/Admin/resetPassword", {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password, token }),
  }).catch((error) => {
    console.error("Error occurred:", error.message);
    throw error;
  });
};

export const getClients = () => {
  return fetch(API_URL + "/Admin/client", {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error) => {
    throw error;
  });
};

export const getTestimonialData = () => {
  return fetch(API_URL + "/Admin/SuccessStoriesMember", {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error) => {
    throw error;
  });
};

export const getCompanyInfo = () => {
  return fetch(API_URL + "/Admin/company/info", {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error) => {
    throw error;
  });
};

export const getMetrics = () => {
  return fetch(API_URL + "/Admin/Metrics", {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error) => {
    throw error;
  });
};

export const getLeaderShipTeam = () => {
  return fetch(API_URL + "/Admin/leadershipTeam", {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error) => {
    throw error;
  });
};

export const deleteLeadershipTeamMember = (id, token) => {
  return fetch(`${API_URL}/Admin/leadershipTeam/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authHeader(),
    },
    body: JSON.stringify({ token }), // Include the token in the request body
  }).catch((error) => {
    throw error; // Propagate the error to the caller
  });
};

export const deleteClient = (id, token) => {
  return fetch(`${API_URL}/Admin/client/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authHeader(),
    },
    body: JSON.stringify({ token }), // Include the token in the request body
  }).catch((error) => {
    throw error; // Propagate the error to the caller
  });
};

export const updateCompanyInfo = (History, Philosophy, Vision, Mission) => {
  return fetch(API_URL + "/Admin/company/info", {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authHeader(),
    },
    body: JSON.stringify({
      History,
      Philosophy,
      Vision,
      Mission,
    }),
  }).catch((error) => {
    throw error;
  });
};

export const updateMetrics = (Clients, Verticals, Countries, token) => {
  console.log(Clients, Verticals, Countries);
  return fetch(API_URL + "/Admin/Metrics", {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authHeader(),
    },
    body: JSON.stringify({
      Clients,
      Verticals,
      Countries,
      token,
    }),
  }).catch((error) => {
    throw error;
  });
};

export const addNewClient = async (Name, Image, Description) => {
  const formData = new FormData();
  formData.append("Name", Name);
  formData.append("Description", Description);
  formData.append("file", Image); // Make sure to append the image file

  const response = await fetch(API_URL + "/Admin/new/client", {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Authorization: "Bearer " + authHeader(),
    },
    body: formData, // No need to set Content-Type; fetch will handle this automatically for FormData
  });

  return response;
};

export const addNewLeadershipMember = async (
  FirstName,
  LastName,
  Role,
  Image
) => {
  const formData = new FormData();
  formData.append("FirstName", FirstName);
  formData.append("LastName", LastName);
  formData.append("Role", Role);
  formData.append("file", Image);

  const response = await fetch(API_URL + "/Admin/leadershipTeam", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + authHeader(),
    },
    body: formData,
  });

  return response;
};

export const addNewTestimonialsSuccessStoriesMember = async (
  FirstName,
  LastName,
  Role,
  Description,
  Image
) => {
  const formData = new FormData();
  formData.append("FirstName", FirstName);
  formData.append("LastName", LastName);
  formData.append("Role", Role);
  formData.append("Description", Description);
  formData.append("file", Image);

  const response = await fetch(API_URL + "/Admin/SuccessStoriesMember", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + authHeader(),
    },
    body: formData,
  });

  return response;
};

export const deleteTestimonialsSuccessStoriesMember = (id, token) => {
  return fetch(`${API_URL}/Admin/SuccessStoriesMember/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authHeader(),
    },
    body: JSON.stringify({ token }), // Include the token in the request body
  }).catch((error) => {
    throw error; // Propagate the error to the caller
  });
};

export const contactUS = (name, email, company, message) => {
  return fetch(API_URL + "/Admin/ContactUS", {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      company,
      message,
    }),
  }).catch((error) => {
    throw error;
  });
};

export const fetchCarers = (formData, id) => {
  console.log("formData", formData);
  return fetch(API_URL + `/Admin/apply/careers/${id}`, {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
    },
    body: formData,
  }).catch((error) => {
    throw error;
  });
};

export const resetPassword = (email) => {
  return fetch(API_URL + "/Admin/reset/password", {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ Email: email }),
  }).catch((error) => {
    console.error("Error occurred:", error.message);
    throw error;
  });
};
