import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
// import authHeader from "../../services/Auth-Loader";

const Layout = (props) => {
  // const isAuth = authHeader();
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />
      <div style={{ flex: 1 }}>{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
