import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useDispatch } from "react-redux";
import ResumeUploader from "./ResumeUploader";
import { Form, useLoaderData, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../PageTitle";
import Loading from "../Loading/Loading";
import { SuccessAction } from "../Store";

const CareerApply = () => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    salaryExpectation: "",
    coverLetter: "",
  });
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_SERVER_API;
  const { id } = useParams();
  const fileInputRef = useRef(null); // Reference to ResumeUploader
  const data = useLoaderData();

  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [serverMessage, setServerMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error on change
  };

  const handleFileChange = (selectedFile) => {
    setFile(selectedFile);
    setErrors((prevErrors) => ({ ...prevErrors, resume: "" })); // Clear resume error on change
  };
  const clearFileInput = () => {
    setFile(null); // Clear file state
    fileInputRef.current?.clearSelectedFile(); // Clear input in ResumeUploader
  };

  // const validateForm = () => {
  //   const newErrors = {};
  //   ["firstName", "lastName", "email", "address", "salaryExpectation"].forEach(
  //     (field) => {
  //       if (!formValues[field].trim()) {
  //         newErrors[field] = "This field is required";
  //       }
  //     }
  //   );
  //   if (!file) {
  //     newErrors.file = "Resume is required";
  //   }
  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setServerMessage(null);

    const formData = new FormData();
    Object.entries(formValues).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (file) {
      formData.append("file", file); // Ensure the resume file is added to the form data
    }
    if (data?.JobTitle) {
      formData.append("JobTitle", data.JobTitle);
    }

    try {
      const response = await fetch(API_URL + `/Admin/apply/careers/${id}`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (!response.ok) {
        if (data.errors) {
          setErrors(data.errors);
          setServerMessage({
            type: "error",
            text: "Please fix the errors above.",
          });
        } else {
          setServerMessage({
            type: "error",
            text: "An unexpected error occurred.",
          });
        }
      } else {
        setServerMessage({ type: "success", text: data.message });
        setFormValues({
          firstName: "",
          lastName: "",
          email: "",
          address: "",
          salaryExpectation: "",
          coverLetter: "",
        });
        clearFileInput(); // Clear the file input on successful submission
        setErrors({});
        dispatch(SuccessAction.toggle());
        navigate("/submission-success");
      }
    } catch (error) {
      setServerMessage({
        type: "error",
        text: "An error occurred during submission.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle title={`${data.JobTitle} - Apply`} />
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h4">{data.JobTitle}</Typography>
      </div> */}
      <Box
        sx={{
          display: "flex",
          // flexDirection: "column",
          justifyContent: "center",
          margin: "30px 0",
          padding: { xs: "0 20px", md: "0 150px" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            padding: "30px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            borderRadius: "10px",
            boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
          }}
        >
          <Typography
            variant="h5"
            mb={4}
            align="center"
            gutterBottom
            fontWeight="600"
            fontFamily='"Roboto", "Helvetica", "Arial", sans-serif'
            color="primary"
          >
            APPLICATION -{" "}
            <Typography
              variant="span"
              color="primary"
              style={{ fontStyle: "italic" }}
            >
              {data.JobTitle}
            </Typography>
          </Typography>

          {serverMessage && (
            <Alert severity={serverMessage.type} sx={{ mb: 2 }}>
              {serverMessage.text}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  variant="filled"
                  fullWidth
                  value={formValues.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  InputLabelProps={{
                    style: {
                      fontWeight: 600,
                      fontSize: "17px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  variant="filled"
                  fullWidth
                  value={formValues.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  InputLabelProps={{
                    style: {
                      fontWeight: 600,
                      fontSize: "17px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  variant="filled"
                  fullWidth
                  value={formValues.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  InputLabelProps={{
                    style: {
                      fontWeight: 600,
                      fontSize: "17px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  name="address"
                  variant="filled"
                  fullWidth
                  value={formValues.address}
                  onChange={handleChange}
                  error={!!errors.address}
                  helperText={errors.address}
                  InputLabelProps={{
                    style: {
                      fontWeight: 600,
                      fontSize: "17px",
                    },
                  }}
                />
                <FormHelperText>
                  Include your city, region, and country.
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Salary Expectations"
                  name="salaryExpectation"
                  variant="filled"
                  fullWidth
                  value={formValues.salaryExpectation}
                  onChange={handleChange}
                  error={!!errors.salaryExpectation}
                  helperText={errors.salaryExpectation}
                  inputProps={{ min: 0, inputMode: "numeric" }}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  InputLabelProps={{
                    style: {
                      fontWeight: 600,
                      fontSize: "17px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="span"
                  color={"secondary"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }}
                >
                  Resume *
                </Typography>
                <ResumeUploader
                  getSelectedFile={handleFileChange}
                  ref={fileInputRef} // Pass ref to ResumeUploader
                />
                {errors.resume && (
                  <FormHelperText error>{errors.resume}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Cover Letter"
                  name="coverLetter"
                  variant="filled"
                  fullWidth
                  multiline
                  rows={4}
                  value={formValues.coverLetter}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: {
                      fontWeight: 600,
                      fontSize: "17px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{ color: "white" }}
                  fullWidth
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Submit Application"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Box>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)", // Slightly transparent background
            zIndex: 1300, // Ensure it overlays all content
          }}
        >
          <Loading />
        </Box>
      )}
    </>
  );
};

export default CareerApply;
