import React from "react";
import { Typography } from "@mui/material";
import KeepInTouch from "./KeepInTouch";
import GoogleMapIntergration from "./GoogleMapIntergration";
import ContactUsHeader from "./ContactUsHeader";
import PageTitle from "../PageTitle";

const ContactUs = () => {
  return (
    <>
      <PageTitle title={"Contact Us"} />
      <Typography
        component="div"
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "100px",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        <Typography
          sx={{
            padding: {
              xs: "0px 30px",
              md: "0px 70px",
              xl: "0px 200px",
            },
          }}
        >
          <ContactUsHeader />
        </Typography>
        <Typography
          sx={{
            padding: {
              xs: "0px 30px",
              md: "0px 70px",
              xl: "0px 200px",
            },
          }}
        >
          <KeepInTouch />
        </Typography>
        <Typography
          sx={{
            padding: {
              xs: "0px 30px",
              md: "0px 70px",
              xl: "0px 200px",
            },
          }}
        >
          <GoogleMapIntergration />
        </Typography>
      </Typography>
    </>
  );
};

export default ContactUs;
