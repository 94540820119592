import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Stack, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

const defaultIcon = new L.Icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

function WhyLebanonRoadMap() {
  useEffect(() => {
    AOS.init();
  }, []);
  function AnimatedPoint({ title, index }) {
    return (
      <div
        data-aos="fade-up"
        data-aos-duration={`${index * 100}`}
        style={{
          display: "flex",
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        <svg
          width="20"
          height={"30"}
          style={{ marginRight: "10px", flexShrink: 0 }}
        >
          <circle cx="10" cy="10" r="6" fill="rgb(0, 169, 184)" />
          <line
            x1="10"
            y1="13"
            x2="10"
            y2="30"
            stroke="rgb(0, 169, 184)"
            strokeWidth="1"
          />
        </svg>
        <Typography
          style={{
            margin: 0,
            fontSize: "16px",
            flex: 1,
            fontFamily: "Montserrat",
            alignItems: "center",
          }}
        >
          {title}
        </Typography>
      </div>
    );
  }

  function SmoothZoomToLebanon() {
    const map = useMap();

    useEffect(() => {
      let isMounted = true;

      map.setView([20, 0], 2, { animate: true });

      const zoomSteps = [
        { coords: [20, 0], zoom: 2 },
        { coords: [30, 27], zoom: 5 },
        { coords: [33.8547, 35.8623], zoom: 7 },
      ];

      const smoothZoom = (index) => {
        if (isMounted && index < zoomSteps.length) {
          map.setView(zoomSteps[index].coords, zoomSteps[index].zoom, {
            animate: true,
            duration: 1.5,
          });
          setTimeout(() => smoothZoom(index + 1), 1800);
        }
      };

      setTimeout(() => {
        if (isMounted) smoothZoom(0);
      }, 1000);

      // Cleanup function to cancel any ongoing animations
      return () => {
        isMounted = false;
      };
    }, [map]);

    return null;
  }

  const points = [
    "Good Technical and Professional Talent Pool",
    "Talent at competitive pricing range",
    "Fluent in Different Languages",
    "Problem Solving Mindset",
    "Local Talent Pool Highly Attracted by Offshore Companies",
    "Commendable Support from Universities and Local Establishments",
    "Lebanese talent best fit for Valsoft & Aspire’s “Be humble, Stay Hungry” ",
  ];

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        width: "100%",
        height: { xs: "800px", md: "500px" },
      }}
    >
      {/* Left section for Map */}
      <div style={{ flex: 1 }}>
        <MapContainer
          style={{ width: "100%", height: "100%" }}
          center={[20, 0]}
          zoom={2}
          scrollWheelZoom={false}
          zoomControl={false}
          zoomAnimation={true}
          animate={true}
          attributionControl={false}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
          <SmoothZoomToLebanon />
          <Marker position={[33.8547, 35.8623]} icon={defaultIcon}>
            <Popup>Lebanon</Popup>
          </Marker>
        </MapContainer>
      </div>

      {/* Right section for Header and Points */}
      <Typography
        sx={{
          padding: "20px",
          backgroundColor: "#f7f7f7", // Light background color
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          overflowY: "auto",
          flex: {
            xs: "initial", // Use default behavior for extra small screens
            md: 1, // Set flex: 1 for medium screens and above
          },
        }}
      >
        {
          <Typography
            component={"h2"}
            variant={"h5"}
            style={{
              fontWeight: "bold",
              opacity: 0,
              animation: "fadeInDown 0.5s ease-out forwards",
              marginBottom: "30px",
              textAlign: "center",
              fontSize: "32px",
            }}
            color={"secondary"}
          >
            Why Lebanon?
          </Typography>
        }
        <Typography
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {points.map((point, index) => (
            <AnimatedPoint
              key={index}
              title={point}
              delay={index * 0.2 + 0.5}
            />
          ))}
        </Typography>
      </Typography>

      <style jsx>{`
        @keyframes fadeInRight {
          from {
            opacity: 0;
            transform: translateX(20px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }
        @keyframes fadeInDown {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </Stack>
  );
}

export default WhyLebanonRoadMap;
