import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { contactUS } from "../../services/Admin.service";
import { Snackbar, Alert } from "@mui/material";
import Loading from "../Loading/Loading";

const KeepInTouch = () => {
  useEffect(() => {
    AOS.init({ duration: 600, easing: "ease-in-out", once: true });
  }, []);

  // State variables for form fields
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  // Enhanced error state to include error messages
  const [errors, setErrors] = useState({
    name: { error: false, message: "" },
    email: { error: false, message: "" },
    company: { error: false, message: "" },
    message: { error: false, message: "" },
  });

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    severity: "success",
    message: "Message sent successfully!",
  });

  // Handler for input changes
  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
    // Clear error when user starts typing
    if (errors[field].error) {
      setErrors({
        ...errors,
        [field]: { error: false, message: "" },
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {
      name: {
        error: !formData.name.trim(),
        message: !formData.name.trim() ? "Name is required" : "",
      },
      email: {
        error: !formData.email.trim(),
        message: !formData.email.trim() ? "Email is required" : "",
      },
      company: {
        error: !formData.company.trim(),
        message: !formData.company.trim() ? "Company is required" : "",
      },
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error.error);
  };

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const response = await contactUS(
        formData.name,
        formData.email,
        formData.company,
        formData.message
      );

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          severity: "success",
          message: "Message sent successfully!",
        });
        setOpenSnackbar(true);

        // Reset form on successful submission
        setFormData({
          name: "",
          email: "",
          company: "",
          message: "",
        });
      } else {
        // Handle validation errors from backend
        if (data.errors) {
          // Update errors state with backend validation messages
          const newErrors = { ...errors };
          Object.keys(data.errors).forEach((field) => {
            newErrors[field] = {
              error: true,
              message: data.errors[field],
            };
          });
          setErrors(newErrors);
        } else {
          // Handle other types of errors
          setSnackbarMessage({
            severity: "error",
            message: data.message || "An error occurred. Please try again.",
          });
          setOpenSnackbar(true);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbarMessage({
        severity: "error",
        message: "Network error. Please try again.",
      });
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ top: "24px" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarMessage.severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          padding: "30px 60px 30px 60px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          borderRadius: "10px",
          boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
          position: "relative",
        }}
        data-aos="fade-up"
      >
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={6}>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            data-aos="fade-right"
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.1rem",
                lineHeight: 2,
                marginBottom: 2,
                color: "#333",
              }}
              data-aos="fade-up"
            >
              Whether you've got a question about Aspire Software or would like
              to introduce us to your company, we'd love to hear from you. Just
              use the form on this page to drop us a line and a member of our
              team will be in touch as soon as possible.
            </Typography>

            <Box>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#333" }}
                data-aos="fade-up"
              >
                Aspire GDC Lebanon
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#555" }}
                data-aos="fade-up"
              >
                Dolphin Fattal Building 6th floor
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#555" }}
                data-aos="fade-up"
              >
                Sin El Fil - Beirut
              </Typography>
              <Box display="flex" alignItems="center" mt={2} data-aos="fade-up">
                <Typography
                  variant="body1"
                  sx={{ color: "#555", mr: 1 }}
                  data-aos="fade-up"
                >
                  Phone:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#555", fontWeight: "bold" }}
                  data-aos="fade-up"
                >
                  +961-03-162-916
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            flex={1}
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            data-aos="fade-left"
          >
            <TextField
              fullWidth
              label="Name"
              variant="filled"
              margin="normal"
              value={formData.name}
              onChange={handleChange("name")}
              error={errors.name.error}
              helperText={errors.name.message}
              InputLabelProps={{
                style: {
                  fontWeight: 600,
                  fontSize: "17px",
                },
              }}
            />
            <TextField
              fullWidth
              label="Email"
              variant="filled"
              margin="normal"
              value={formData.email}
              onChange={handleChange("email")}
              error={errors.email.error}
              helperText={errors.email.message}
              InputLabelProps={{
                style: {
                  fontWeight: 600,
                  fontSize: "17px",
                },
              }}
            />
            <TextField
              fullWidth
              label="Company"
              variant="filled"
              margin="normal"
              value={formData.company}
              onChange={handleChange("company")}
              error={errors.company.error}
              helperText={errors.company.message}
              InputLabelProps={{
                style: {
                  fontWeight: 600,
                  fontSize: "17px",
                },
              }}
            />
            <TextField
              fullWidth
              label="Message - optional"
              variant="filled"
              margin="normal"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange("message")}
              InputLabelProps={{
                style: {
                  fontWeight: 600,
                  fontSize: "17px",
                },
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              sx={{ mt: 2, color: "white" }}
              type="submit"
              disabled={loading}
            >
              {loading ? "Sending ..." : "Send"}
            </Button>
          </Box>
        </Box>
      </Box>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)", // Slightly transparent background
            zIndex: 1300, // Ensure it overlays all content
          }}
        >
          <Loading />
        </Box>
      )}
    </>
  );
};

export default KeepInTouch;
