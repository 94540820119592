import React, { useEffect, useState } from "react";
import MainSection from "./MainSection";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./Main.module.css";
import { Box, Typography } from "@mui/material";
import ContainerSection from "./ContainerSection";
import WhyLebanonRoadMap from "./WhyLebanonRoadMap";
import ImageSlider from "./ImageSlider";
import ExternalMain from "./ExternalMain";
import CeoMessage from "./CeoMessage";
import AOS from "aos";
import "aos/dist/aos.css";

import PageTitle from "../PageTitle";
import { getClients, getMetrics } from "../../services/Admin.service";
import Loading from "../Loading/Loading";
const Main = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const firstSectionStyle = {
    width: { xl: "100%", lg: "100%" },
    height: { xl: "1000px", lg: "800px", md: "700px", xs: "100%" },
    borderRadius: "10px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getClients();
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Typography
      sx={{
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "100px",
        justifyContent: "center",
        padding: {
          xs: "0px 30px ",
          md: "0px 70px",
          xl: "0px 200px",
        },
      }}
    >
      <PageTitle title={"Home"} />
      <MainSection style={firstSectionStyle}>
        <video
          src="https://www.aspiresoftware.com/wp-content/uploads/2023/04/Sequence-01_8-1.mp4"
          autoPlay
          loop
          muted
          playsInline
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
        />
      </MainSection>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Loading />
        </Box>
      ) : (
        <ImageSlider data={data} description={false} setData={setData} />
      )}
      <ExternalMain />
      <ContainerSection>
        <CeoMessage />
      </ContainerSection>
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255,0.7)",
          borderRadius: "10px",
          boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
          marginBottom: "20px",
        }}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <WhyLebanonRoadMap />
      </Box>
    </Typography>
  );
};

export default Main;

export const AuthMainLoader = async ({ request, params }) => {
  const MetricsResponse = await getMetrics();
  const metrics = await MetricsResponse.json();

  return metrics;
};
