import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";

const Loading = () => {
  const theme = useTheme();
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => {
        if (prev.length < 3) {
          return prev + ".";
        } else {
          return ""; // Reset to an empty string after three dots
        }
      });
    }, 400); // Adjust the timing as needed

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  return (
    <Box
      component="div"
      sx={{
        fontSize: "20px",
        fontWeight: 700,
        color: theme.palette.primary.main,
        fontFamily: "monospace",
        overflow: "hidden",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@keyframes fadeInOut": {
          "0%, 100%": { opacity: 0.15 },
          "50%": { opacity: 1 },
        },
        animation: "fadeInOut 1.4s infinite",
        transition: "opacity 0.8s ease-in-out",
      }}
    >
      <Box component="span" sx={{ marginTop: "2px" }}>
        GDC LEBANON SARL{dots}
      </Box>
    </Box>
  );
};

export default Loading;
