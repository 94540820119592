import React from 'react'
import styles from "./StyledButton.module.css"

const StyledButton = (props) => {
  return (
    <div>
       <button className={`${styles.buttonBase} ${styles.button}`}>
        {props.name}
      </button>
    </div>
  )
}

export default StyledButton
