import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Card, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const MyCard = (props) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (count < props.number) {
        setCount((prevCount) => prevCount + 5);
      }
    }, 100);
    return () => clearTimeout(interval);
  });

  return (
    <Stack data-aos="zoom-in">
      <Card
        sx={{
          width: { lg: "100%", md: "100%", sm: "100%" },
          backgroundColor: "#F8F8F8",
          height: { lg: "250px", md: "210px", sm: "240px", xs: "220px" },
          display: "flex",
          flexDirection: "column",
          borderRadius: "15px",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography variant="h2" component="div">
            {props.data}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            position={"absolute"}
            bottom={"7px"}
          >
            <Typography component="div">{props.icon}</Typography>
            <Typography
              variant="p"
              component="div"
              sx={{ fontWeight: 600, fontSize: "20px" }}
            >
              {props.name}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default MyCard;
