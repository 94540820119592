import React from "react";
import { getCompanyInfo, getMetrics } from "../services/Admin.service";
import Admin from "../component/Admin/Admin";
import authHeader from "../services/Auth-Loader";
import { redirect } from "react-router-dom";

function AdminPage() {
  return (
    <>
      <Admin />
    </>
  );
}
export default AdminPage;

export const AuthSettingsLoader = async ({ request, params }) => {
  const companyInfoResponse = await getCompanyInfo();
  const companyInfo = await companyInfoResponse.json();

  const MetricsResponse = await getMetrics();
  const metrics = await MetricsResponse.json();

  const token = authHeader();
  if (!token) {
    return redirect("/Admin/login");
  }
  return { companyInfo, metrics };
};
