import { Box } from "@mui/material";
import "./CeoMessage.css"; // Create this file for custom styles
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const ContainerSection = (props) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Box
      className="floating-container" // Add a class for the floating effect
      sx={{
        padding: "60px 60px 60px 60px",
        backgroundColor: "rgba(255, 255, 255,0.7)",
        borderRadius: "10px",
        boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
      }}
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      {props.children}
    </Box>
  );
};

export default ContainerSection;
