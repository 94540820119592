import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AOS from "aos";
import "aos/dist/aos.css";
import authHeader from "../../services/Auth-Loader";
import { deleteTestimonialsSuccessStoriesMember } from "../../services/Admin.service";

const TestimonialCard = ({ data, setData }) => {
  const [open, setOpen] = useState(false); // State for managing dialog open/close
  const token = authHeader();
  useEffect(() => {
    AOS.init({ duration: 1200, easing: "ease-in-out", once: true });
  }, []);

  // Function to handle delete icon click
  const handleDeleteClick = () => {
    setOpen(true); // Open the dialog
  };

  // Function to handle dialog close
  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  // Function to confirm deletion
  const handleConfirmDelete = async () => {
    const id = data._id;
    try {
      const response = await deleteTestimonialsSuccessStoriesMember(id, token);

      if (response.ok) {
        // Update the state by filtering out the deleted testimonial
        setData((prevData) => prevData.filter((item) => item._id !== id));
        handleClose(); // Close the dialog after deletion
      } else {
        // Handle error case, e.g., show a message or log it
        console.error("Failed to delete testimonial");
      }
    } catch (error) {
      console.error("Error deleting testimonial:", error);
    }
  };
  return (
    <Box
      sx={{
        position: "relative",
        width: {
          xs: "100%",
          sm: "90%",
          md: "100%",
          lg: "550px",
        },
        maxWidth: "550px",
        margin: {
          xs: "30px auto",
          md: "30px auto",
          lg: "30px 0 30px 60px",
        },
      }}
      data-aos="fade-up"
    >
      {token && (
        <IconButton
          onClick={handleDeleteClick} // Open dialog on delete icon click
          sx={{
            position: "absolute",
            top: -10,
            right: 5,
          }}
        >
          <DeleteIcon color="error" />
        </IconButton>
      )}

      <Box
        sx={{
          backgroundColor: "rgba(51,51,51,0.7)",
          borderRadius: { xs: "30px", md: "70px" },
          padding: {
            xs: "50px 20px 20px",
            sm: "10px 10px 10px 125px",
          },
          position: "relative",
          overflow: "visible",
          minHeight: { xs: "200px", sm: "160px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        data-aos="fade-right"
      >
        <Typography variant="h6" component="div">
          <Typography
            component="span"
            sx={{
              color: "#3dd2ba",
              fontWeight: "bold",
              fontSize: { xs: "18px", sm: "21px" },
            }}
          >
            {data.FirstName} {data.LastName}
          </Typography>
          <Typography
            component="span"
            sx={{
              color: "white",
              fontWeight: "normal",
              marginLeft: "5px",
              fontSize: { xs: "14px", sm: "16px" },
            }}
          >
            — {data.Role}
          </Typography>
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mt: 1,
            color: "white",
            fontSize: { xs: "12px", sm: "14px" },
          }}
        >
          {data.Description}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: { xs: "50%", sm: -20 },
          top: { xs: -40, sm: 25 },
          transform: { xs: "translateX(-50%)", sm: "none" },
        }}
        data-aos="zoom-in"
      >
        <Box
          sx={{
            position: "absolute",
            top: -4,
            left: -5,
            right: 4,
            bottom: 2,
            borderRadius: "50%",
            background: "rgba(61, 210, 186, 0.7)",
          }}
        />
        <Box
          component="img"
          src={`${process.env.REACT_APP_SERVER_API}${data?.Image}`}
          alt={data?.Image}
          sx={{
            width: { xs: 100, sm: 130 },
            height: { xs: 100, sm: 130 },
            border: "3px solid #212529",
            borderRadius: "50%",
            position: "relative",
            objectFit: "cover",
          }}
        />
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this testimonial?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TestimonialCard;
