import React, { useEffect } from "react";
import { Box, Typography, Grid, Container, Paper } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { styled } from "@mui/system";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import FlexibleIcon from "@mui/icons-material/AllInclusive";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LanguageIcon from "@mui/icons-material/Language";
import WorkIcon from "@mui/icons-material/Work";
import PublicIcon from "@mui/icons-material/Public";
import GavelIcon from "@mui/icons-material/Gavel";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import BackgroundImage from "../../Image/Benefits-Background.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: theme.spacing(2),
  backgroundColor: "transparent",
  boxShadow: "none",
}));

const IconWrapper = styled(Box)(({ color }) => ({
  color: color,
  marginBottom: (theme) => theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const BackgroundOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.95)",
  zIndex: 1,
}));

const Benefits = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const sections = [
    {
      icon: <MonetizationOnOutlinedIcon sx={{ fontSize: 50 }} />,
      title: "Cost Efficiency",
      content: [
        "Lower Labor Costs: Highly skilled professionals at competitive rates",
        "Reduced Overheads: Lower administrative costs for HR, payroll, and compliance",
      ],
      color: "rgb(19, 32, 60)",
    },
    {
      icon: <PeopleOutlineIcon sx={{ fontSize: 50 }} />,
      title: "Access to Skilled Talent",
      content: [
        "Diverse Skill Set: Highly educated workforce proficient in IT, engineering, finance, and more",
        "Specialized Expertise: Access to niche talent pools for specialized roles",
      ],
      color: "rgb(0, 169, 184)",
    },
    {
      icon: <FlexibleIcon sx={{ fontSize: 50 }} />,
      title: "Flexibility and Scalability",
      content: [
        "On-Demand Hiring: Scale teams up or down based on project needs",
        "Temporary or Long-Term Employment: Choose between different contract lengths",
      ],
      color: "#DF8977",
    },
    {
      icon: <AccessTimeIcon sx={{ fontSize: 50 }} />,
      title: "Time Zone Advantage",
      content: [
        "Efficient Collaboration: GMT +2 time zone overlaps with European and Middle Eastern business hours",
      ],
      color: "rgb(76, 175, 80)",
    },
    {
      icon: <LanguageIcon sx={{ fontSize: 50 }} />,
      title: "Cultural Compatibility",
      content: [
        "Western Business Practices: Familiarity with international business standards",
        "Multilingual Workforce: Smooth communication with global clients and stakeholders",
      ],
      color: "rgb(255, 152, 0)",
    },
    {
      icon: <WorkIcon sx={{ fontSize: 50 }} />,
      title: "Strong Work Ethic",
      content: [
        "Resilience and adaptability",
        "High level of commitment to roles",
      ],
      color: "rgb(233, 30, 99)",
    },
    {
      icon: <PublicIcon sx={{ fontSize: 50 }} />,
      title: "Proximity to Europe and Middle East",
      content: [
        "Geographical Advantage: Easier travel, shorter flight times",
        "Better alignment with cultural and business practices in these regions",
      ],
      color: "rgb(63, 81, 181)",
    },
    {
      icon: <GavelIcon sx={{ fontSize: 50 }} />,
      title: "Compliance and Regulatory Support",
      content: [
        "Simplified Legal Procedures: Expertise in international employment laws",
        "Easier navigation of compliance, contracts, and legal formalities",
      ],
      color: "rgb(121, 85, 72)",
    },
    {
      icon: <TrendingDownIcon sx={{ fontSize: 50 }} />,
      title: "Lower Turnover Rates",
      content: [
        "Stable employment in a challenging economic environment",
        "Higher retention rates compared to other regions",
      ],
      color: "rgb(158, 158, 158)",
    },
  ];

  return (
    <Typography data-aos="zoom-up" data-aos-duration="1000">
      <Box
        sx={{
          position: "relative",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh", // Adjust the height as needed
        }}
      >
        <BackgroundOverlay />
        <Box sx={{ position: "relative", zIndex: 2 }}>
          <h1
            style={{
              fontFamily: "Titillium Web, sans-serif",
              fontSize: "34px",
              textAlign: "center",
              padding: "20px 0px 0px 0px",
            }}
            data-aos="fade-up"
            data-aos-duration="1100"
          >
            Benefits of GDC Lebanon
          </h1>
          <Container maxWidth="xl" sx={{ padding: "30px" }}>
            <Grid container spacing={3} sx={{ padding: "10px" }}>
              {sections.map((section, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  data-aos="flip-up"
                  data-aos-delay={`${index * 100}`}
                >
                  <StyledPaper elevation={1}>
                    <IconWrapper color={section.color} data-aos="zoom-in">
                      {section.icon}
                    </IconWrapper>
                    <Box
                      sx={{
                        width: 60,
                        height: 2,
                        bgcolor: section.color,
                        mb: 3,
                        mx: "auto",
                      }}
                    />
                    <Typography
                      variant="h5"
                      component="h2"
                      gutterBottom
                      sx={{
                        color: section.color,
                        fontWeight: 700,
                        mb: 2,
                        fontFamily: "Poppins, sans-serif",
                      }}
                      data-aos="fade-down"
                    >
                      {section.title}
                    </Typography>
                    <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
                      {section.content.map((item, i) => (
                        <li key={i}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              lineHeight: 1.5,
                              fontFamily: "Roboto, sans-serif",
                              marginBottom: 1,
                            }}
                          >
                            {item}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </StyledPaper>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Box>
    </Typography>
  );
};

export default Benefits;
