import React, { useState } from "react";
import { Form, useLoaderData } from "react-router-dom";
import { Button, Typography, InputAdornment, TextField } from "@mui/material";
import { updateMetrics } from "../../services/Admin.service";
import authHeader from "../../services/Auth-Loader";

function Metrics() {
  const data = useLoaderData();
  const token = authHeader();
  const [clients, setClients] = useState(data?.metrics?.Clients);
  const [verticals, setVerticals] = useState(data?.metrics?.Verticals);
  const [countries, setCountries] = useState(data?.metrics?.Countries);
  const [isLoading, setIsLoading] = useState(false);
  const changeClientsHandler = (event) => {
    setClients(event.target.value);
  };
  const changeVerticalsHandler = (event) => {
    setVerticals(event.target.value);
  };
  const changeCountriesHandler = (event) => {
    setCountries(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    setIsLoading(true);
    try {
      const response = await updateMetrics(
        Number(clients),
        Number(verticals),
        Number(countries),
        token
      );
      console.log("Update successful", response);
    } catch (error) {
      console.error("Error updating company info:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Form
      style={{ display: "flex", flexDirection: "column" }}
      method="Post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Typography
        variant="h5"
        sx={{
          color: "rgb(19, 32, 60)",
          textAlign: "center",
          mb: 2,
          fontWeight: "bold",
          padding: "10px 0px",
        }}
      >
        Metrics
      </Typography>
      <TextField
        value={clients}
        name="Client"
        label="Client"
        onChange={changeClientsHandler}
        type="text"
        sx={{ m: "10px 0 0 0", width: { xs: "25ch", md: "35ch", sm: "25ch" } }}
        variant="filled"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 34.964 41.772"
              >
                <g
                  id="Group_44763"
                  data-name="Group 44763"
                  transform="translate(-1045.137 -1110)"
                >
                  <path
                    id="Path_7053"
                    data-name="Path 7053"
                    d="M180.2,203.162h10.41V178H175v25.157Z"
                    transform="translate(879.812 947.61)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <line
                    id="Line_4374"
                    data-name="Line 4374"
                    x2="1.735"
                    transform="translate(1049.607 1142.097)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <rect
                    id="Rectangle_1226"
                    data-name="Rectangle 1226"
                    width="12.145"
                    height="4.337"
                    transform="translate(1056.547 1121.277)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></rect>
                  <rect
                    id="Rectangle_1227"
                    data-name="Rectangle 1227"
                    width="5.205"
                    height="4.337"
                    transform="translate(1060.017 1116.94)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></rect>
                  <line
                    id="Line_4375"
                    data-name="Line 4375"
                    x2="8.675"
                    transform="translate(1058.282 1129.952)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4376"
                    data-name="Line 4376"
                    x2="8.675"
                    transform="translate(1058.282 1135.157)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4377"
                    data-name="Line 4377"
                    x2="8.675"
                    transform="translate(1058.282 1140.362)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4378"
                    data-name="Line 4378"
                    x2="8.675"
                    transform="translate(1058.282 1145.567)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4379"
                    data-name="Line 4379"
                    x2="1.735"
                    transform="translate(1049.607 1131.687)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4380"
                    data-name="Line 4380"
                    x2="1.735"
                    transform="translate(1049.607 1128.217)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4381"
                    data-name="Line 4381"
                    x2="1.735"
                    transform="translate(1049.607 1135.157)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4382"
                    data-name="Line 4382"
                    x2="1.735"
                    transform="translate(1049.607 1138.627)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4383"
                    data-name="Line 4383"
                    x2="1.735"
                    transform="translate(1049.607 1145.567)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <path
                    id="Path_7054"
                    data-name="Path 7054"
                    d="M193,203.427h8.675V180.005H193"
                    transform="translate(877.427 947.345)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <line
                    id="Line_4384"
                    data-name="Line 4384"
                    y2="2.602"
                    transform="translate(1074.764 1135.157)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4385"
                    data-name="Line 4385"
                    y2="2.602"
                    transform="translate(1074.764 1130.82)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4386"
                    data-name="Line 4386"
                    y2="2.602"
                    transform="translate(1074.764 1139.495)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4387"
                    data-name="Line 4387"
                    y2="2.602"
                    transform="translate(1074.764 1144.699)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    id="Line_4388"
                    data-name="Line 4388"
                    y1="6.94"
                    transform="translate(1062.619 1110)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <path
                    id="Path_7055"
                    data-name="Path 7055"
                    d="M191,175.005h8.675v4.337"
                    transform="translate(877.692 948.007)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    id="Path_7056"
                    data-name="Path 7056"
                    d="M175.41,169.945v-6.072l-.867-.867L165,172.547v28.627h7.807"
                    transform="translate(881.137 949.597)"
                    fill="none"
                    stroke="#0c6677"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                </g>
              </svg>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { fontWeight: 600, fontSize: "17px", color: "#0c6677" },
        }}
      />
      <TextField
        value={verticals}
        name="Verticals"
        label="Verticals"
        onChange={changeVerticalsHandler}
        type="text"
        sx={{ m: "10px 0 0 0", width: { xs: "25ch", md: "35ch", sm: "25ch" } }}
        variant="filled"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 46.172 46.658"
              >
                <g
                  id="Group_44853"
                  data-name="Group 44853"
                  transform="translate(-1513.759 -1213.275)"
                >
                  <path
                    id="Path_874"
                    data-name="Path 874"
                    d="M1534.128,1241.835a1.464,1.464,0,0,1-.319.2c-1.435.669-3.726-1.207-5.117-4.191s-1.357-5.945.078-6.614a1.379,1.379,0,0,1,.184-.071"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <line
                    id="Line_459"
                    data-name="Line 459"
                    x2="18"
                    transform="translate(1533.43 1238.922)"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <path
                    id="Path_875"
                    data-name="Path 875"
                    d="M1553.93,1241.922l-2.5-3,2.5-3h5l-2,3,2,3Z"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <line
                    id="Line_460"
                    data-name="Line 460"
                    y1="12.498"
                    x2="17.95"
                    transform="translate(1531.856 1224.171)"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <path
                    id="Path_876"
                    data-name="Path 876"
                    d="M1553.584,1225.161l-3.777-.99.292-3.894,4.07-2.9.115,3.6,3.37,1.281Z"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <line
                    id="Line_461"
                    data-name="Line 461"
                    y1="11.716"
                    x2="8.384"
                    transform="translate(1530.899 1222.111)"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <path
                    id="Path_877"
                    data-name="Path 877"
                    d="M1543.179,1221.842l-3.9.269-.968-3.783,2.928-4.053,1.26,3.378,3.6.136Z"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    id="Path_878"
                    data-name="Path 878"
                    d="M1539.63,1238.922c1.268,5.466.351,10.168-2.633,11.56-3.924,1.83-9.935-2.757-13.427-10.246s-3.142-15.041.782-16.871c2.215-1.033,5.1-.021,7.812,2.4"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    id="Path_879"
                    data-name="Path 879"
                    d="M1537.39,1232.81q.2.394.389.8.409.879.749,1.757"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    id="Path_880"
                    data-name="Path 880"
                    d="M1534.7,1228.514q.543.7,1.059,1.479"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    id="Path_881"
                    data-name="Path 881"
                    d="M1544.281,1238.923c2.237,8.941.626,16.861-4.5,19.251-6.468,3.017-16.083-3.914-21.477-15.481s-4.522-23.387,1.946-26.4c4.166-1.943,9.639.242,14.448,5.135"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    id="Path_882"
                    data-name="Path 882"
                    d="M1541,1230.3q.374.723.723,1.473.741,1.59,1.325,3.179"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    id="Path_883"
                    data-name="Path 883"
                    d="M1537.467,1224.645q.9,1.2,1.743,2.518"
                    fill="none"
                    stroke="#a45342"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                </g>
              </svg>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { fontWeight: 600, fontSize: "17px", color: "#a45342" },
        }}
      />
      <TextField
        value={countries}
        name="Countries"
        label="Countries"
        onChange={changeCountriesHandler}
        type="text"
        sx={{ m: "10px 0 0 0", width: { xs: "25ch", md: "35ch", sm: "25ch" } }}
        variant="filled"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 51.243 58.426"
              >
                <g
                  id="Group_44240"
                  data-name="Group 44240"
                  transform="translate(1.5 1.5)"
                >
                  <line
                    id="Line_2314"
                    data-name="Line 2314"
                    x1="9.898"
                    y1="5.868"
                    transform="translate(29.705 6.794)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2315"
                    data-name="Line 2315"
                    y1="12.061"
                    transform="translate(44.626 21.71)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2316"
                    data-name="Line 2316"
                    y2="12.061"
                    transform="translate(3.618 21.71)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2317"
                    data-name="Line 2317"
                    x1="9.897"
                    y2="5.868"
                    transform="translate(8.64 6.794)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2318"
                    data-name="Line 2318"
                    x1="9.898"
                    y2="5.868"
                    transform="translate(29.705 42.977)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2319"
                    data-name="Line 2319"
                    x1="9.897"
                    y1="5.868"
                    transform="translate(8.64 42.976)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <path
                    id="Path_4038"
                    data-name="Path 4038"
                    d="M3499.441,1603.385a3.619,3.619,0,1,1-3.621-3.619A3.62,3.62,0,0,1,3499.441,1603.385Z"
                    transform="translate(-3471.699 -1599.766)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></path>
                  <path
                    id="Path_4039"
                    data-name="Path 4039"
                    d="M3495.755,1646.949a3.529,3.529,0,0,1-3.552-3.593,3.686,3.686,0,0,1,3.686-3.589,3.527,3.527,0,0,1,3.552,3.591,3.687,3.687,0,0,1-3.686,3.59"
                    transform="translate(-3471.699 -1591.523)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></path>
                  <path
                    id="Path_4040"
                    data-name="Path 4040"
                    d="M3516.443,1633.382a3.618,3.618,0,1,1-3.618-3.616A3.619,3.619,0,0,1,3516.443,1633.382Z"
                    transform="translate(-3468.199 -1593.584)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></path>
                  <path
                    id="Path_4041"
                    data-name="Path 4041"
                    d="M3482.475,1633.386a3.584,3.584,0,0,1-3.6,3.618,3.669,3.669,0,0,1-3.671-3.619,3.585,3.585,0,0,1,3.6-3.619A3.667,3.667,0,0,1,3482.475,1633.386Z"
                    transform="translate(-3475.207 -1593.583)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></path>
                  <path
                    id="Path_4042"
                    data-name="Path 4042"
                    d="M3482.443,1613.384a3.618,3.618,0,1,1-3.618-3.618A3.619,3.619,0,0,1,3482.443,1613.384Z"
                    transform="translate(-3475.207 -1597.705)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></path>
                  <path
                    id="Path_4043"
                    data-name="Path 4043"
                    d="M3497.678,1639.712a14.473,14.473,0,1,0-14.471-14.474A14.473,14.473,0,0,0,3497.678,1639.712Z"
                    transform="translate(-3473.554 -1597.499)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></path>
                  <path
                    id="Path_4044"
                    data-name="Path 4044"
                    d="M3496.439,1610.766c-4,0-7.236,6.479-7.236,14.472s3.24,14.474,7.236,14.474,7.238-6.479,7.238-14.471S3500.437,1610.766,3496.439,1610.766Z"
                    transform="translate(-3472.317 -1597.499)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></path>
                  <line
                    id="Line_2320"
                    data-name="Line 2320"
                    x1="26.534"
                    transform="translate(10.854 22.916)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2321"
                    data-name="Line 2321"
                    x1="26.534"
                    transform="translate(10.854 32.564)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2322"
                    data-name="Line 2322"
                    y2="28.946"
                    transform="translate(24.123 13.267)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2323"
                    data-name="Line 2323"
                    x1="2.754"
                    y2="1.821"
                    transform="translate(6.796 35.936)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2324"
                    data-name="Line 2324"
                    x2="2.754"
                    y2="1.821"
                    transform="translate(38.671 35.936)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2325"
                    data-name="Line 2325"
                    y1="1.821"
                    x2="2.754"
                    transform="translate(38.671 17.722)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2326"
                    data-name="Line 2326"
                    x1="2.754"
                    y1="1.821"
                    transform="translate(6.796 17.722)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <path
                    id="Path_4045"
                    data-name="Path 4045"
                    d="M3516.443,1613.384a3.618,3.618,0,1,1-3.618-3.617A3.617,3.617,0,0,1,3516.443,1613.384Z"
                    transform="translate(-3468.199 -1597.705)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></path>
                  <line
                    id="Line_2327"
                    data-name="Line 2327"
                    y2="3.618"
                    transform="translate(24.123 7.236)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                  <line
                    id="Line_2328"
                    data-name="Line 2328"
                    y1="3.618"
                    transform="translate(24.123 44.625)"
                    fill="none"
                    stroke="#4ca4b4"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  ></line>
                </g>
              </svg>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { fontWeight: 600, fontSize: "17px", color: "#4ca4b4" },
        }}
      />
      <Button
        type="submit"
        sx={{ marginTop: "20px" }}
        color="primary"
        variant="contained"
        disabled={isLoading}
      >
        {isLoading ? "Updating..." : "Update"}
      </Button>
    </Form>
  );
}

export default Metrics;
