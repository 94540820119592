import { Typography } from "@mui/material";
// import styles from "../Container/RootContainer.module.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme/theme";

const RootContainer = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Typography>{props.children}</Typography>
    </ThemeProvider>
  );
};
export default RootContainer;
