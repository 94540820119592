import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useSpring, animated } from "react-spring";
import img from "../../Image/Careers-First-Section.png";
import AOS from "aos";
import "aos/dist/aos.css";

const WhyJoin = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      offset: 200,
      easing: "ease-in-out",
      delay: 100,
    });

    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const imageAnimation = useSpring({
    transform: `translateY(${scrollY * 0.08}px)`,
    config: { mass: 1, tension: 180, friction: 20 },
  });

  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        boxShadow: "0 25px 70px rgba(0, 0, 0, 0.07)",
        borderRadius: 2,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        padding: { xs: "30px", sm: "40px", md: "50px" },
        gap: { xs: 3, md: 4 },
        alignItems: { xs: "center", md: "flex-start" }, // Center items on small screens
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography
          variant={isSmallScreen ? "h5" : "h4"}
          component="h1"
          gutterBottom
          sx={{ color: "#003366", fontWeight: "bold" }}
          data-aos="fade-up"
          data-aos-duration="900"
        >
          Why Join ?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: { xs: 3, md: 7 },
            lineHeight: 2.1,
            fontSize: { xs: "0.9rem", sm: "1.2rem" },
          }}
          data-aos="fade-right"
          data-aos-duration="1300"
        >
          At Valsoft, we thrive on more than just collaboration and teamwork—we
          cultivate an entrepreneurial mindset that drives innovation and
          growth. Our mantra, "Be Humble, Stay Hungry," reflects our commitment
          to continuous learning, personal growth, and a hunger for success.
          Whether it's through fostering leadership, offering mentorship, or
          celebrating our collective achievements, we build an environment that
          keeps the spark of entrepreneurship alive every day.
        </Typography>
      </Box>
      <animated.div style={imageAnimation}>
        <Box
          component="img"
          src={img}
          alt="Entrepreneurial Spirit"
          sx={{
            width: "100%",
            height: "auto",
            maxWidth: { xs: "100%", sm: "300px", md: "350px" },
            marginTop: { xs: 3, md: 0 },
            transition: "transform 0.3s ease",
            display: { xs: "block", md: "initial" }, // Ensure it behaves like a block on small screens
          }}
          data-aos="zoom-in"
          data-aos-duration="1100"
        />
      </animated.div>
    </Box>
  );
};

export default WhyJoin;
