export default function authHeader() {
  const token = localStorage.getItem("token");
  const expirationTime = localStorage.getItem("expirationTime");

  // Check if the token is present and if it's expired
  if (token && expirationTime) {
    const currentTime = new Date().getTime();
    if (currentTime < expirationTime) {
      return token; // Token is valid
    } else {
      localStorage.removeItem("token"); // Remove expired token
      localStorage.removeItem("expirationTime"); // Remove expired expiration time
    }
  }
  return null; // No valid token
}
