import {
  InputAdornment,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import StyledButtonComponent from "../StyledComponent/StyledButtonComponent";
import { resetPassword } from "../../services/Admin.service";

function ResetPassword() {
  const [errors, setErrors] = useState();
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false); // Corrected variable name
  const [isUserNameFocused, setisUserNameFocused] = useState(false);
  const changeUserNameHandler = (event) => {
    setEmail(event.target.value);
  };
  const handleUserNameFocus = () => {
    setisUserNameFocused(true);
  };

  const handleUserNameBlur = () => {
    setisUserNameFocused(false);
  };
  const submitHandler = (event) => {
    event.preventDefault();
    setSubmitting(true); // Start submitting state

    resetPassword(email)
      .then((response) => {
        if (response.ok) {
          setEmail("");
          setOpen(true);
          return navigate("/reset-password");
        }
        return response.json();
      })
      .then((error) => {
        setErrors(error);
        navigate("/reset-password");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Form
      style={{ display: "flex", flexDirection: "column" }}
      method="Post"
      onSubmit={submitHandler}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          sx={{
            backgroundColor: "rgb(19, 32, 60)",
          }}
          message="Kindly check your email."
        />
      </Snackbar>
      <h2 style={{ color: "rgb(19, 32, 60)", textAlign: "center" }}>
        Reset Password
      </h2>
      <TextField
        name="userName"
        label="Email"
        id="outlined-start-adornment"
        sx={{ m: "10px 0 0 0", width: { xs: "25ch", md: "40ch", sm: "35ch" } }}
        variant="filled"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {
                <MailOutlineIcon
                  style={{
                    color: isUserNameFocused ? "rgb(19, 32, 60)" : "inherit",
                  }}
                />
              }
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { fontWeight: 600, fontSize: "17px" },
        }}
        onChange={changeUserNameHandler}
        value={email}
        onFocus={handleUserNameFocus}
        onBlur={handleUserNameBlur}
      />
      {errors &&
        errors.message.split(",").map((error, index) => (
          <Typography
            key={index}
            variant="p"
            color="error"
            sx={{ fontSize: "13px", fontWeight: "bold" }}
          >
            {error}
          </Typography>
        ))}
      <StyledButtonComponent
        submiting={submitting}
        title="Reset"
        submitTitle="Reseting..."
      />
    </Form>
  );
}

export default ResetPassword;
